import 'firebase/firestore';
import { setCache, getCache } from '../../utilities/CacheController';
import { useEffect, useMemo, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import {
  GatewayInstance,
  GatewaysCollection,
  HardwareCollection,
  HardwareInstance,
  SensorInstance,
  SensorsCollection,
  UserId,
} from '../../model';
import { myFirebase } from '../firebaseConfig';

const db = myFirebase.firestore();

type UserStruct = {
  organization_id: string;
  role: string;
};

type UseHardwareHook = {
  hardwareData: HardwareCollection;
  selectedHardware: string;
  buildDocTree: () => Promise<void>;
};

const HARDWARE_CACHE_KEY = 'hardware_cache';

export const useHardware = (userId: UserId): UseHardwareHook => {
  const [dataReady, setDataReady] = useState<boolean>(false);
  const [data, setData] = useState<HardwareCollection>({});
  const [selectedHardware, setSelectedHardware] = useState<string>('');

  const buildDocTree = async () => {
    //Attempt to load cache first, and proceed to pulling from firebase if not present
    const cachedValue = await getCache<HardwareCollection>(HARDWARE_CACHE_KEY);

    if (cachedValue != null) {
      setData(cachedValue);
      setDataReady(true);
      return;
    }

    const userDocRef = db.collection('users').doc(userId);
    const userDocs = await userDocRef.get();
    const userData = userDocs.data() as UserStruct;
    const hwCollectionRef = db.collection('organizations').doc(userData.organization_id).collection('hardware');
    const hardwareDocs = await hwCollectionRef.get();

    // Represents root
    const hardwareCollection: HardwareCollection = {};

    // For each /users/{userId}/hardware/{hardwareId}
    for (const hwi of hardwareDocs.docs) {
      const hardwareInstanceData: HardwareInstance = hwi.data() as HardwareInstance;

      //Because we're only trying to get usable hardware out of the databse, check if hardware is of type 'router'
      if (hardwareInstanceData.type != 'router') {
        return;
      }

      const gatewayCollectionRef = hwCollectionRef.doc(hwi.id).collection('gateways');
      const gatewayDocs = await gatewayCollectionRef.get();

      const gatewayCollectionData: GatewaysCollection = {};

      // For each /users/{userId}/hardware/{hardwareId}/gateways/{gatewayId}
      for (const gwi of gatewayDocs.docs) {
        const gatewayInstanceData = gwi.data() as GatewayInstance;

        const sensorCollectionRef = gatewayCollectionRef.doc(gwi.id).collection('sensors');
        const sensorDocs = await sensorCollectionRef.get();

        const sensorCollectionData: SensorsCollection = {};

        // For each /users/{userId}/hardware/hardwareId/gateways/{gatewayId}/sensors/{serialNumber}
        for (const si of sensorDocs.docs) {
          const sensorInstanceData = si.data() as SensorInstance;

          sensorCollectionData[si.id] = {
            ...sensorInstanceData,
          };
        }

        gatewayCollectionData[gwi.id] = {
          ...gatewayInstanceData,
          sensors: sensorCollectionData,
        };
      }

      hardwareCollection[hwi.id] = {
        ...hardwareInstanceData,
        gateways: gatewayCollectionData,
      };
    }

    setData(hardwareCollection);
    setDataReady(true);
    await setCache<HardwareCollection>(hardwareCollection, HARDWARE_CACHE_KEY);
  };

  // useMemo(buildDocTree, [userDocs]);

  // onMount, build the doctree (this happens once per 'reload or refresh')
  useEffect(() => {
    buildDocTree();
  }, []);

  const hook: UseHardwareHook = {
    hardwareData: data,
    selectedHardware: selectedHardware,
    buildDocTree: buildDocTree,
  };
  return useMemo(() => hook, [dataReady]);
};
