/** The Run Kinematics report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonItem,
  IonIcon,
  IonChip,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonImg,
  IonModal,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { Report, RunReportData } from '../../../model';

import { closeCircleOutline, informationCircleOutline, personOutline, pricetagsOutline } from 'ionicons/icons';
import { NameField_v2, toAttractorLabels } from '../components/Fields';
import { useState } from 'react';
import { transpose } from '../../../hooks/useReports/transform';
import {
  ModalAnkleStiffness,
  ModalFootPlant,
  ModalHipLock,
  ModalKneeExtensionPreswing,
  ModalKneeFlexionMidstance,
  ModalKneeFlexionMidswing,
  ModalPositiveRunningMidstance,
  ModalPositiveRunningToeoff,
  ModalProjectionFootplant,
  ModalScissors,
  ModalSwingLegRecovery,
  ModalSwingLegRetraction,
} from '../components/ModalsRun';
import ankleStiffness from '../../../assets/avatars/run/analysis/AnkleStiffness.png';
import footPlant from '../../../assets/avatars/run/analysis/FootPlant.png';
import hipLock from '../../../assets/avatars/run/analysis/HipLock.png';
import kneeAngleMidswing from '../../../assets/avatars/run/analysis/KneeAngleMidswing.png';
import kneeExtensionPreswing from '../../../assets/avatars/run/analysis/KneeExtensionPreswing.png';
import kneeFlexionMidstance from '../../../assets/avatars/run/analysis/kneeFlexionMidstance.png';
import positiveRunningMidstance from '../../../assets/avatars/run/analysis/PositiveRunningMidstance.png';
import positiveRunningToeOff from '../../../assets/avatars/run/analysis/PositiveRunningToeOff.png';
import projectionFootplant from '../../../assets/avatars/run/analysis/ProjectionFootplant.png';
import scissors from '../../../assets/avatars/run/analysis/Scissors.png';
import swingLegRecovery from '../../../assets/avatars/run/analysis/SwingLegRecovery.png';
import swingLegRetraction from '../../../assets/avatars/run/analysis/SwingLegRetraction.png';
import { AttractorCard } from '../components/Cards';

type AttractorsReportProps = RouteComponentProps & {
  report: RunReportData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunAttractorsReportTab = ({ report }: AttractorsReportProps) => {
  const data = report as RunReportData;

  const attractorData = transpose(data?.reportId.attractors);
  const attractorLabels = toAttractorLabels(data?.reportId.attractors);

  if (!data) {
    return <IonText>Data not found.</IonText>;
  }

  // get the name of the person
  //   const indexToSlice = report.filename.indexOf('-');
  //   const name = report.filename.slice(0, indexToSlice - 1);

  return (
    <IonPage>
      <IonContent className='ion-padding' color='light'>
        {data && (
          <IonGrid fixed>
            <IonRow className='ion-no-padding'>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Ankle stiffness'
                  subtitle='Terminal stance'
                  info={<ModalAnkleStiffness />}
                  img={ankleStiffness}
                  labels={attractorLabels[1]}
                  attractorRow={attractorData[1]}></AttractorCard>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee extension'
                  subtitle='Terminal stance'
                  info={<ModalKneeExtensionPreswing />}
                  img={kneeExtensionPreswing}
                  labels={attractorLabels[2]}
                  attractorRow={attractorData[2]}></AttractorCard>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Hip lock'
                  subtitle='Terminal stance'
                  info={<ModalHipLock />}
                  img={hipLock}
                  labels={attractorLabels[3]}
                  attractorRow={attractorData[3]}></AttractorCard>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Positive running'
                  subtitle='Toe-off'
                  info={<ModalPositiveRunningToeoff />}
                  img={positiveRunningToeOff}
                  labels={attractorLabels[0]}
                  attractorRow={attractorData[0]}></AttractorCard>
              </IonCol>
            </IonRow>

            <IonRow className='ion-no-padding'>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Swing leg recovery'
                  subtitle='Initial swing'
                  info={<ModalSwingLegRecovery />}
                  img={swingLegRecovery}
                  labels={attractorLabels[5]}
                  attractorRow={attractorData[5]}></AttractorCard>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee flexion'
                  subtitle='Midswing'
                  info={<ModalKneeFlexionMidswing />}
                  img={kneeAngleMidswing}
                  labels={attractorLabels[6]}
                  attractorRow={attractorData[6]}></AttractorCard>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Swing leg retraction'
                  subtitle='Terminal Swing'
                  info={<ModalSwingLegRetraction />}
                  img={swingLegRetraction}
                  labels={attractorLabels[9]}
                  attractorRow={attractorData[9]}></AttractorCard>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Scissors motion'
                  subtitle='Terminal swing'
                  info={<ModalScissors />}
                  img={scissors}
                  labels={attractorLabels[10]}
                  attractorRow={attractorData[10]}></AttractorCard>
              </IonCol>
            </IonRow>

            <IonRow className='ion-no-padding'>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Foot plant from above'
                  subtitle='Midstance'
                  info={<ModalFootPlant />}
                  img={footPlant}
                  labels={attractorLabels[11]}
                  attractorRow={attractorData[11]}></AttractorCard>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Projection foot plant'
                  subtitle='Midstance'
                  info={<ModalProjectionFootplant />}
                  img={projectionFootplant}
                  labels={attractorLabels[8]}
                  attractorRow={attractorData[8]}></AttractorCard>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee flexion'
                  subtitle='Midstance'
                  info={<ModalKneeFlexionMidstance />}
                  img={kneeFlexionMidstance}
                  labels={attractorLabels[14]}
                  attractorRow={attractorData[14]}></AttractorCard>
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Positive running'
                  subtitle='Midstance'
                  info={<ModalPositiveRunningMidstance />}
                  img={positiveRunningMidstance}
                  labels={attractorLabels[7]}
                  attractorRow={attractorData[7]}></AttractorCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};
