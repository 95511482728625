/** Card (section) in a report with a title, content, and info button */
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
} from '@ionic/react';
import { closeCircleOutline, informationCircleOutline } from 'ionicons/icons';
import * as React from 'react';
import { useState } from 'react';
import './index.css';

type ReportSectionProps = {
  title: string;
  subtitle?: string;
  info: React.ReactNode;
  children: React.ReactNode;
};

type GraphReportSectionProps = {
  title: string;
  subtitle?: string;
  info: React.ReactNode;
  graph: React.ReactNode;
};

type ReportTableSectionProps = {
  title: string;
  subtitle?: string;
  info: React.ReactNode;
  children: React.ReactNode;
};

/** Component for a report section with a title, subtitle, info button and content
 * @param info - The info component, shown as a modal when clicking on the info button
 * @param children - The content of the section
 */
export const ReportSection = ({ title, subtitle, info, children }: ReportSectionProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <IonModal isOpen={showModal} cssClass='reportInfo_Modal' onDidDismiss={() => setShowModal(false)}>
        {info}
        <IonButton slot='icon-only' onClick={() => setShowModal(false)} fill='clear' className='reportInfo_Modal_Close'>
          <IonIcon icon={closeCircleOutline} />
        </IonButton>
      </IonModal>

      <IonCard className='dashboardCard_Full'>
        <IonCardHeader className='attractorCard_Header'>
          <IonGrid fixed className='ion-no-padding'>
            <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row'>
              <IonCol size='9' className=''>
                <IonCardTitle className='attractorCard_Title'>{title}</IonCardTitle>
                {subtitle && <IonCardSubtitle className='attractorCard_Subtitle'>{subtitle}</IonCardSubtitle>}
              </IonCol>

              <IonCol size='3' className='ion-justify-content-center'>
                <IonButtons className='ion-justify-content-center'>
                  <IonButton
                    slot='icon-only'
                    fill='clear'
                    className='attractorCard_Header_Buttons'
                    onClick={() => setShowModal(true)}>
                    <IonIcon icon={informationCircleOutline} />
                  </IonButton>
                </IonButtons>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardHeader>
        <IonCardContent className=''>{children}</IonCardContent>
      </IonCard>
    </>
  );
};

export const GraphReportSection = ({ title, graph }: GraphReportSectionProps) => {
  return (
    <>
      <IonCard className='reportCard_Full'>
        <IonCardHeader className='reportCard_Header'>
          <IonCardTitle className='reportCard_Title'>{title}</IonCardTitle>
        </IonCardHeader>

        <IonCardContent className='reportCard_Graph_content'>{graph}</IonCardContent>
      </IonCard>
    </>
  );
};

export const ReportTableSection = ({ title, subtitle, children }: ReportTableSectionProps) => {
  return (
    <>
      <IonCard className='reportCard_Full'>
        <IonCardHeader className='reportCard_Header'>
          <div>
            <IonCardTitle className='reportCard_Title'>{title}</IonCardTitle>
            {subtitle && <IonCardSubtitle className='reportCard_Subtitle'>{subtitle}</IonCardSubtitle>}
          </div>
        </IonCardHeader>
        <IonCardContent className='reportCard_Full_content'>{children}</IonCardContent>
      </IonCard>
    </>
  );
};
