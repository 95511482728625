/** Walk analysis report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonItem,
  IonIcon,
  IonChip,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonModal,
  IonList,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import ankle from '../../../assets/avatars/walk/analysis/Ankle.png';
import hip from '../../../assets/avatars/walk/analysis/Hip.png';
import hipExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalStance.png';
import hipExtensionAtTerminalSwing from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalSwing.png';
import knee from '../../../assets/avatars/walk/analysis/Knee.png';
import kneeFlexionAtMidstance from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidstance.png';
import kneeFlexionAtMidswing from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidswing.png';
import kneeExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/KneeExtensionAtTerminalStance.png';
import kneeStabilityAtLoadingResponse from '../../../assets/avatars/walk/analysis/KneeStabilityAtLoadingResponse.png';
import pelvicObliquity from '../../../assets/avatars/walk/analysis/PelvicObliquity.png';
import pelvis from '../../../assets/avatars/walk/analysis/Pelvis.png';
import varusValgus from '../../../assets/avatars/walk/analysis/VarusValgus.png';
import { transpose, toMatrix } from '../../../hooks/useReports';
import { Report, WalkReportData } from '../../../model';
import { NameField, StatusDotField } from '../components/Fields';
import { ReportSection } from '../components/ReportSection';
import './WalkAnalysisReportTab.css';
import {
  ModalHipExtensionTerminalStance,
  ModalKneeFlexionMidstance,
  ModalKneeFlexionMidswing,
  ModalKneeStabilityLoadingResponse,
  ModalPelvicObliquity,
  ModalVarusValgus,
  ModalMobilityStatus,
} from '../components/ModalsWalk';
import { closeCircleOutline, informationCircleOutline, personOutline, pricetagsOutline } from 'ionicons/icons';
import { ModalHipExtensionTerminalSwing } from '../components/ModalsWalk/ModalHipExtensionTerminalSwing';
import { ModalKneeExtensionTerminalStance } from '../components/ModalsWalk/ModalKneeExtensionTerminalStance';
import { useMemo, useState } from 'react';
import { AttractorCard } from '../../ReportV2/components/Cards';
import { NameField_v2 } from '../../ReportV2/components/Fields';
import { DashboardCard } from '../../Report/components/dashboardCard';

type MobilityProps = {
  title: string;
  img: string;
  romRow: number[];
  statusRow: number[];
};

/** Mobility report card content, showing avatar, left/right status and symmetry fields
 * @param img - The avatar
 * @param romRow - list of ROM data values
 * @param statusRow - list of status field tuples for (Left, Right)
 */
const Mobility = ({ title, img, romRow, statusRow }: MobilityProps) => {
  return (
    <IonGrid className=''>
      <IonRow>
        <IonCol className='ion-no-padding'>
          <IonImg src={img} className='reportAvatar' />
          <IonText className='mobilityReportCard_Title'>{title}</IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <StatusDotField name='Left' value={romRow[0].toFixed(0) + '°'} status={statusRow[0]} />
        </IonCol>
        <IonCol>
          <StatusDotField name='Right' value={romRow[1].toFixed(0) + '°'} status={statusRow[1]} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

const kpiLabels = [
  ['Ok', 'Fair', 'Poor'],
  ['Hike', 'Ok', 'Drop'],
  ['Ok', 'Fair', 'Poor', 'No extension'],
];

type WalkReportProps = RouteComponentProps & {
  report: WalkReportData;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const WalkAnalysisReportTab = ({ report }: WalkReportProps) => {
  const data = report;

  // TODO: useEffect hook to avoid recalculating
  const romData: number[][] = transpose(data?.reportId.rom);
  const kpiData = transpose(data?.reportId.keyPerformanceIndicators);
  const tableData: number[][] = toMatrix(data?.reportId.spatioTemporal);
  const varusValgusLabel_Left = romData[6][0] < 0 ? 'Valgus' : 'Varus';
  const varusValgusLabel_Right = romData[6][1] < 0 ? 'Valgus' : 'Varus';

  if (!data) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed>
            {/* <IonRow className=''>
              <DashboardCard title='Mobility Status' info={<ModalMobilityStatus />}>
                <IonRow>
                  <IonCol size='12'>
                    <IonItem lines='none'>
                      <strong>
                        Stride Duration: {tableData[0][0].toFixed(2)}s.
                        <span className='SpeedLabel' id='showSpeedLabel'>
                          {' '}
                          {speedLabel()}{' '}
                        </span>
                      </strong>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow className='ion-padding-bottom'>
                  <IonCol size='3'>
                    <Mobility title='Pelvis' img={pelvis} romRow={romData[0]} statusRow={statusData[0]} />
                  </IonCol>
                  <IonCol size='3'>
                    <Mobility title='Hip' img={hip} romRow={romData[1]} statusRow={statusData[1]} />
                  </IonCol>
                  <IonCol size='3'>
                    <Mobility title='Knee' img={knee} romRow={romData[4]} statusRow={statusData[2]} />
                  </IonCol>
                  <IonCol size='3'>
                    <Mobility title='Ankle' img={ankle} romRow={romData[7]} statusRow={statusData[3]} />
                  </IonCol>
                </IonRow>
              </DashboardCard>
            </IonRow> */}

            <IonRow>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Hip extension'
                  subtitle='Terminal stance'
                  img={hipExtensionAtTerminalStance}
                  kpiLabels={kpiLabels[0]}
                  attractorRow={kpiData[1]}
                  info={<ModalHipExtensionTerminalStance />}
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee extension'
                  subtitle='Terminal stance'
                  img={kneeExtensionAtTerminalStance}
                  kpiLabels={kpiLabels[2]}
                  attractorRow={kpiData[0]}
                  info={<ModalKneeExtensionTerminalStance />}
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee flexion'
                  subtitle='Midswing'
                  img={kneeFlexionAtMidswing}
                  kpiLabels={kpiLabels[0]}
                  attractorRow={kpiData[6]}
                  info={<ModalKneeFlexionMidswing />}
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Hip extension'
                  subtitle='Terminal swing'
                  img={hipExtensionAtTerminalSwing}
                  kpiLabels={kpiLabels[0]}
                  attractorRow={kpiData[2]}
                  info={<ModalHipExtensionTerminalSwing />}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee stability'
                  subtitle='Loading response'
                  img={kneeStabilityAtLoadingResponse}
                  kpiLabels={kpiLabels[0]}
                  attractorRow={kpiData[3]}
                  info={<ModalKneeStabilityLoadingResponse />}
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Knee flexion'
                  subtitle='Midstance'
                  img={kneeFlexionAtMidstance}
                  kpiLabels={kpiLabels[0]}
                  attractorRow={kpiData[5]}
                  info={<ModalKneeFlexionMidstance />}
                />
              </IonCol>

              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Varus / Valgus'
                  subtitle='Midstance'
                  img={varusValgus}
                  kpiLabels={kpiLabels[0]}
                  attractorRow={kpiData[7]}
                  info={<ModalVarusValgus />}
                  varusValgus={[varusValgusLabel_Left, varusValgusLabel_Right]}
                />
              </IonCol>
              <IonCol size-sm='12' size-md='6' size-lg='3'>
                <AttractorCard
                  title='Pelvic obliquity'
                  subtitle='Midstance'
                  img={pelvicObliquity}
                  kpiLabels={kpiLabels[1]}
                  attractorRow={kpiData[4]}
                  info={<ModalPelvicObliquity />}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
