/** The Run Kinematics report tab */
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonList,
  IonLoading,
  IonModal,
  IonPage,
  IonPopover,
  IonRow,
  IonText,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { Report, RunReportData } from '../../../model';
import {
  toGraphData,
  flattenData,
  toVariabilityData,
  createLegend,
  getPelvisVariabilityData_Left,
  getPelvisVariabilityData_Right,
  getKneeVariabilityData_Left,
  getKneeVariabilityData_Right,
  getAnkleVariabilityData_Left,
  getAnkleVariabilityData_Right,
  createCRPLegend,
} from '../components/DataFunctions';
import {
  VariabilityGraphLegenda,
  VariabilitySettingsPopoverContent,
  colorCS,
  colorCT,
  colorSS,
  colorST,
  colorTC,
  variabilityAll_Left,
  variabilityAll_Right,
  variabilityMean_Left,
  variabilityMean_Right,
  variabilitySelected_Left,
  variabilitySelected_Right,
} from '../components/CustomGraphComponents';

import '../components/Graphs/graphCard.css';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { VariabilityOverviewGraph } from '../components/Graphs';
import { useEffect, useMemo, useState } from 'react';
import ORYX_MagnifyingGlassIcon from '../../../assets/icons/ORYX_MagnifyingGlassIcon.svg';
import { settingsOutline } from 'ionicons/icons';
import { VariabilityModalContent } from '../components/Modals';

type AttractorsReportProps = RouteComponentProps & {
  report: RunReportData;
};

interface LegendaItem {
  name: string;
  color: string;
  id: string;
  side?: string;
}

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunVariabilityReportTab = ({ report }: AttractorsReportProps) => {
  const data = report as RunReportData | undefined;
  const [loading, setLoading] = useState(true);
  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);
  const annotations_Left = data?.reportId.pointsOfInterests.col0;
  const highlightsHip_Left = { x: data?.reportId.pointsOfInterests.col0[4], x2: 100 };
  const highlights_Left = { x: data?.reportId.pointsOfInterests.col0[3], x2: data?.reportId.pointsOfInterests.col0[0] };
  const annotations_Right = data?.reportId.pointsOfInterests.col1;
  const highlightsHip_Right = { x: data?.reportId.pointsOfInterests.col1[4], x2: 100 };
  const highlights_Right = {
    x: data?.reportId.pointsOfInterests.col1[3],
    x2: data?.reportId.pointsOfInterests.col1[0],
  };

  const [pelvisLegenda, setPelvisLegenda] = useState<LegendaItem[]>([]);
  const [kneeLegenda, setKneeLegenda] = useState<LegendaItem[]>([]);
  const [ankleLegenda, setAnkleLegenda] = useState<LegendaItem[]>([]);

  const [showSettings, setShowSettings] = useState({
    show: false,
    event: undefined,
  });

  const [showMoreInfoModal, setShowMoreInfoModal] = useState({
    show: false,
    joint: '',
  });

  const [crpSeriesPelvisThigh_Left, setCrpSeriesPelvisThigh_Left] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityOverviewHip_Left, setCoordinativeVariabilityOverviewHip_Left] = useState<
    ApexOptions['series']
  >([]);
  const [coordinativeVariabilitySeriesHip_Left, setCoordinativeVariabilitySeriesHip_Left] = useState<
    ApexOptions['series']
  >([]);
  const [crpSeriesPelvisThigh_Right, setCrpSeriesPelvisThigh_Right] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityOverviewHip_Right, setCoordinativeVariabilityOverviewHip_Right] = useState<
    ApexOptions['series']
  >([]);
  const [coordinativeVariabilitySeriesHip_Right, setCoordinativeVariabilitySeriesHip_Right] = useState<
    ApexOptions['series']
  >([]);

  const [crpSeriesThighCalf_Left, setCrpSeriesThighCalf_Left] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityOverviewKnee_Left, setCoordinativeVariabilityOverviewKnee_Left] = useState<
    ApexOptions['series']
  >([]);
  const [coordinativeVariabilitySeriesKnee_Left, setCoordinativeVariabilitySeriesKnee_Left] = useState<
    ApexOptions['series']
  >([]);
  const [crpSeriesThighCalf_Right, setCrpSeriesThighCalf_Right] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityOverviewKnee_Right, setCoordinativeVariabilityOverviewKnee_Right] = useState<
    ApexOptions['series']
  >([]);
  const [coordinativeVariabilitySeriesKnee_Right, setCoordinativeVariabilitySeriesKnee_Right] = useState<
    ApexOptions['series']
  >([]);

  const [crpSeriesCalfFoot_Left, setCrpSeriesCalfFoot_Left] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityOverviewAnkle_Left, setCoordinativeVariabilityOverviewAnkle_Left] = useState<
    ApexOptions['series']
  >([]);
  const [coordinativeVariabilitySeriesAnkle_Left, setCoordinativeVariabilitySeriesAnkle_Left] = useState<
    ApexOptions['series']
  >([]);
  const [crpSeriesCalfFoot_Right, setCrpSeriesCalfFoot_Right] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityOverviewAnkle_Right, setCoordinativeVariabilityOverviewAnkle_Right] = useState<
    ApexOptions['series']
  >([]);
  const [coordinativeVariabilitySeriesAnkle_Right, setCoordinativeVariabilitySeriesAnkle_Right] = useState<
    ApexOptions['series']
  >([]);

  const [crpModalSeriesHip_Left, setCrpModalSeriesHip_Left] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityModalSeriesHip_Left, setCoordinativeVariabilityModalSeriesHip_Left] = useState<
    ApexOptions['series']
  >([]);

  const [crpModalSeriesHip_Right, setCrpModalSeriesHip_Right] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityModalSeriesHip_Right, setCoordinativeVariabilityModalSeriesHip_Right] = useState<
    ApexOptions['series']
  >([]);

  const [crpModalSeriesKnee_Left, setCrpModalSeriesKnee_Left] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityModalSeriesKnee_Left, setCoordinativeVariabilityModalSeriesKnee_Left] = useState<
    ApexOptions['series']
  >([]);

  const [crpModalSeriesKnee_Right, setCrpModalSeriesKnee_Right] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityModalSeriesKnee_Right, setCoordinativeVariabilityModalSeriesKnee_Right] = useState<
    ApexOptions['series']
  >([]);

  const [crpModalSeriesAnkle_Left, setCrpModalSeriesAnkle_Left] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityModalSeriesAnkle_Left, setCoordinativeVariabilityModalSeriesAnkle_Left] = useState<
    ApexOptions['series']
  >([]);

  const [crpModalSeriesAnkle_Right, setCrpModalSeriesAnkle_Right] = useState<ApexOptions['series']>([]);
  const [coordinativeVariabilityModalSeriesAnkle_Right, setCoordinativeVariabilityModalSeriesAnkle_Right] = useState<
    ApexOptions['series']
  >([]);

  const {
    crpSeriesDataPelvisThighT_Left,
    meanCrpSeriesDataPelvisThigh_Left,
    crpSeriesDataPelvisCThighT_Left,
    meanCrpSeriesDataPelvisThighT_Left,
    crpSeriesDataPelvisCThigh_Left,
    meanCrpSeriesDataPelvisCThighT_Left,
    crpSeriesDataPelvisThigh_Left,
    meanCrpSeriesDataPelvisCThigh_Left,
    coordinativeVariabilitySeriesAllHip_Left,
    coordinativeVariabilitySeriesMeanHip_Left,
    coordinativeVariabilitySeriesPelvisThigh_Left,
    coordinativeVariabilitySeriesPelvisThighT_Left,
    coordinativeVariabilitySeriesPelvisCThighT_Left,
    coordinativeVariabilitySeriesPelvisCThigh_Left,
  } = useMemo(() => getPelvisVariabilityData_Left(data), [data]);

  const {
    crpSeriesDataPelvisThighT_Right,
    meanCrpSeriesDataPelvisThigh_Right,
    crpSeriesDataPelvisCThighT_Right,
    meanCrpSeriesDataPelvisThighT_Right,
    crpSeriesDataPelvisCThigh_Right,
    meanCrpSeriesDataPelvisCThighT_Right,
    crpSeriesDataPelvisThigh_Right,
    meanCrpSeriesDataPelvisCThigh_Right,
    coordinativeVariabilitySeriesAllHip_Right,
    coordinativeVariabilitySeriesMeanHip_Right,
    coordinativeVariabilitySeriesPelvisThigh_Right,
    coordinativeVariabilitySeriesPelvisThighT_Right,
    coordinativeVariabilitySeriesPelvisCThighT_Right,
    coordinativeVariabilitySeriesPelvisCThigh_Right,
  } = useMemo(() => getPelvisVariabilityData_Right(data), [data]);

  const {
    crpSeriesDataThighCalfT_Left,
    meanCrpSeriesDataThighCalf_Left,
    crpSeriesDataThighCCalfT_Left,
    meanCrpSeriesDataThighCCalfT_Left,
    crpSeriesDataThighCalf_Left,
    meanCrpSeriesDataThighCalfT_Left,
    coordinativeVariabilitySeriesAllKnee_Left,
    coordinativeVariabilitySeriesMeanKnee_Left,
    coordinativeVariabilitySeriesThighCalf_Left,
    coordinativeVariabilitySeriesThighCalfT_Left,
    coordinativeVariabilitySeriesThighCCalfT_Left,
  } = useMemo(() => getKneeVariabilityData_Left(data), [data]);

  const {
    crpSeriesDataThighCalfT_Right,
    meanCrpSeriesDataThighCalf_Right,
    crpSeriesDataThighCCalfT_Right,
    meanCrpSeriesDataThighCCalfT_Right,
    crpSeriesDataThighCalf_Right,
    meanCrpSeriesDataThighCalfT_Right,
    coordinativeVariabilitySeriesAllKnee_Right,
    coordinativeVariabilitySeriesMeanKnee_Right,
    coordinativeVariabilitySeriesThighCalf_Right,
    coordinativeVariabilitySeriesThighCalfT_Right,
    coordinativeVariabilitySeriesThighCCalfT_Right,
  } = useMemo(() => getKneeVariabilityData_Right(data), [data]);

  const {
    crpSeriesDataCalfTFootC_Left,
    meanCrpSeriesDataCalfFoot_Left,
    crpSeriesDataCalfFoot_Left,
    meanCrpSeriesDataCalfTFootC_Left,
    coordinativeVariabilitySeriesAllAnkle_Left,
    coordinativeVariabilitySeriesMeanAnkle_Left,
    coordinativeVariabilitySeriesCalfFoot_Left,
    coordinativeVariabilitySeriesCalfTFootC_Left,
  } = useMemo(() => getAnkleVariabilityData_Left(data), [data]);

  const {
    crpSeriesDataCalfTFootC_Right,
    meanCrpSeriesDataCalfFoot_Right,
    crpSeriesDataCalfFoot_Right,
    meanCrpSeriesDataCalfTFootC_Right,
    coordinativeVariabilitySeriesAllAnkle_Right,
    coordinativeVariabilitySeriesMeanAnkle_Right,
    coordinativeVariabilitySeriesCalfFoot_Right,
    coordinativeVariabilitySeriesCalfTFootC_Right,
  } = useMemo(() => getAnkleVariabilityData_Right(data), [data]);

  useEffect(() => {
    if (!loading) {
      setPelvisLegenda(
        createLegend(
          crpSeriesPelvisThigh_Left,
          coordinativeVariabilityOverviewHip_Left,
          coordinativeVariabilityOverviewHip_Right,
        ),
      );
      setKneeLegenda(
        createLegend(
          crpSeriesThighCalf_Left,
          coordinativeVariabilityOverviewKnee_Left,
          coordinativeVariabilityOverviewKnee_Right,
        ),
      );
      setAnkleLegenda(
        createLegend(
          crpSeriesCalfFoot_Left,
          coordinativeVariabilityOverviewAnkle_Left,
          coordinativeVariabilityOverviewAnkle_Right,
        ),
      );
    }
    setCrpModalSeriesHip_Left([
      ...crpSeriesDataPelvisThigh_Left,
      ...crpSeriesDataPelvisThighT_Left,
      ...crpSeriesDataPelvisCThighT_Left,
      ...crpSeriesDataPelvisCThigh_Left,
    ]);

    setCoordinativeVariabilityModalSeriesHip_Left([
      ...coordinativeVariabilitySeriesPelvisThigh_Left,
      ...coordinativeVariabilitySeriesPelvisThighT_Left,
      ...coordinativeVariabilitySeriesPelvisCThighT_Left,
      ...coordinativeVariabilitySeriesPelvisCThigh_Left,
      ...coordinativeVariabilitySeriesAllHip_Left,
    ]);

    setCrpModalSeriesHip_Right([
      ...crpSeriesDataPelvisThigh_Right,
      ...crpSeriesDataPelvisThighT_Right,
      ...crpSeriesDataPelvisCThighT_Right,
      ...crpSeriesDataPelvisCThigh_Right,
    ]);

    setCoordinativeVariabilityModalSeriesHip_Right([
      ...coordinativeVariabilitySeriesPelvisThigh_Right,
      ...coordinativeVariabilitySeriesPelvisThighT_Right,
      ...coordinativeVariabilitySeriesPelvisCThighT_Right,
      ...coordinativeVariabilitySeriesPelvisCThigh_Right,
      ...coordinativeVariabilitySeriesAllHip_Right,
    ]);

    setCrpModalSeriesKnee_Left([
      ...crpSeriesDataThighCalf_Left,
      ...crpSeriesDataThighCalfT_Left,
      ...crpSeriesDataThighCCalfT_Left,
    ]);

    setCoordinativeVariabilityModalSeriesKnee_Left([
      ...coordinativeVariabilitySeriesThighCalf_Left,
      ...coordinativeVariabilitySeriesThighCalfT_Left,
      ...coordinativeVariabilitySeriesThighCCalfT_Left,
      ...coordinativeVariabilitySeriesAllKnee_Left,
    ]);

    setCrpModalSeriesKnee_Right([
      ...crpSeriesDataThighCalf_Right,
      ...crpSeriesDataThighCalfT_Right,
      ...crpSeriesDataThighCCalfT_Right,
    ]);

    setCoordinativeVariabilityModalSeriesKnee_Right([
      ...coordinativeVariabilitySeriesThighCalf_Right,
      ...coordinativeVariabilitySeriesThighCalfT_Right,
      ...coordinativeVariabilitySeriesThighCCalfT_Right,
      ...coordinativeVariabilitySeriesAllKnee_Right,
    ]);

    setCrpModalSeriesAnkle_Left([...crpSeriesDataCalfFoot_Left, ...crpSeriesDataCalfTFootC_Left]);

    setCoordinativeVariabilityModalSeriesAnkle_Left([
      ...coordinativeVariabilitySeriesCalfFoot_Left,
      ...coordinativeVariabilitySeriesCalfTFootC_Left,
      ...coordinativeVariabilitySeriesAllAnkle_Left,
    ]);

    setCrpModalSeriesAnkle_Right([...crpSeriesDataCalfFoot_Right, ...crpSeriesDataCalfTFootC_Right]);

    setCoordinativeVariabilityModalSeriesAnkle_Right([
      ...coordinativeVariabilitySeriesCalfFoot_Right,
      ...coordinativeVariabilitySeriesCalfTFootC_Right,
      ...coordinativeVariabilitySeriesAllAnkle_Right,
    ]);
  }, [loading]);

  useEffect(() => {
    const allStridesLocalStorage_Item = localStorage.getItem('showAllStridesVariability');
    if (allStridesLocalStorage_Item === 'true') {
      setShowAllStrides(true);
      setCrpSeriesPelvisThigh_Left([
        ...crpSeriesDataPelvisThighT_Left,
        ...crpSeriesDataPelvisCThighT_Left,
        ...crpSeriesDataPelvisCThigh_Left,
        ...crpSeriesDataPelvisThigh_Left,
      ]);
      setCoordinativeVariabilityOverviewHip_Left([
        ...coordinativeVariabilitySeriesAllHip_Left,
        // ...coordinativeVariabilitySeriesSelectedHip_Left,
        // ...coordinativeVariabilitySeriesMeanHip_Left,
      ]);

      setCrpSeriesPelvisThigh_Right([
        ...crpSeriesDataPelvisThighT_Right,
        ...crpSeriesDataPelvisCThighT_Right,
        ...crpSeriesDataPelvisCThigh_Right,
        ...crpSeriesDataPelvisThigh_Right,
      ]);

      setCoordinativeVariabilityOverviewHip_Right([
        ...coordinativeVariabilitySeriesAllHip_Right,
        // ...coordinativeVariabilitySeriesSelectedHip_Right,
        // ...coordinativeVariabilitySeriesMeanHip_Right,
      ]);

      setCrpSeriesThighCalf_Left([
        ...crpSeriesDataThighCalfT_Left,
        ...crpSeriesDataThighCCalfT_Left,
        ...crpSeriesDataThighCalf_Left,
      ]);

      setCoordinativeVariabilityOverviewKnee_Left([
        ...coordinativeVariabilitySeriesAllKnee_Left,
        // ...coordinativeVariabilitySeriesSelectedKnee_Left,
        // ...coordinativeVariabilitySeriesMeanKnee_Left,
      ]);

      setCrpSeriesThighCalf_Right([
        ...crpSeriesDataThighCalfT_Right,
        ...crpSeriesDataThighCCalfT_Right,
        ...crpSeriesDataThighCalf_Right,
      ]);

      setCoordinativeVariabilityOverviewKnee_Right([
        ...coordinativeVariabilitySeriesAllKnee_Right,
        // ...coordinativeVariabilitySeriesSelectedKnee_Right,
        // ...coordinativeVariabilitySeriesMeanKnee_Right,
      ]);

      setCrpSeriesCalfFoot_Left([...crpSeriesDataCalfTFootC_Left, ...crpSeriesDataCalfFoot_Left]);

      setCoordinativeVariabilityOverviewAnkle_Left([
        ...coordinativeVariabilitySeriesAllAnkle_Left,
        // ...coordinativeVariabilitySeriesSelectedAnkle_Left,
        // ...coordinativeVariabilitySeriesMeanAnkle_Left,
      ]);

      setCrpSeriesCalfFoot_Right([...crpSeriesDataCalfTFootC_Right, ...crpSeriesDataCalfFoot_Right]);

      setCoordinativeVariabilityOverviewAnkle_Right([
        ...coordinativeVariabilitySeriesAllAnkle_Right,
        // ...coordinativeVariabilitySeriesSelectedAnkle_Right,
        // ...coordinativeVariabilitySeriesMeanAnkle_Right,
      ]);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else {
      localStorage.setItem('showAllStridesVariability', 'false');
      setShowAllStrides(false);
      setCrpSeriesPelvisThigh_Left([
        ...meanCrpSeriesDataPelvisThighT_Left,
        ...meanCrpSeriesDataPelvisCThighT_Left,
        ...meanCrpSeriesDataPelvisCThigh_Left,
        ...meanCrpSeriesDataPelvisThigh_Left,
      ]);
      setCoordinativeVariabilityOverviewHip_Left([...coordinativeVariabilitySeriesMeanHip_Left]);

      setCrpSeriesPelvisThigh_Right([
        ...meanCrpSeriesDataPelvisThighT_Right,
        ...meanCrpSeriesDataPelvisCThighT_Right,
        ...meanCrpSeriesDataPelvisCThigh_Right,
        ...meanCrpSeriesDataPelvisThigh_Right,
      ]);

      setCoordinativeVariabilityOverviewHip_Right([...coordinativeVariabilitySeriesMeanHip_Right]);

      setCrpSeriesThighCalf_Left([
        ...meanCrpSeriesDataThighCalfT_Left,
        ...meanCrpSeriesDataThighCCalfT_Left,
        ...meanCrpSeriesDataThighCalf_Left,
      ]);

      setCoordinativeVariabilityOverviewKnee_Left([...coordinativeVariabilitySeriesMeanKnee_Left]);

      setCrpSeriesThighCalf_Right([
        ...meanCrpSeriesDataThighCalfT_Right,
        ...meanCrpSeriesDataThighCCalfT_Right,
        ...meanCrpSeriesDataThighCalf_Right,
      ]);

      setCoordinativeVariabilityOverviewKnee_Right([...coordinativeVariabilitySeriesMeanKnee_Right]);

      setCrpSeriesCalfFoot_Left([...meanCrpSeriesDataCalfTFootC_Left, ...meanCrpSeriesDataCalfFoot_Left]);

      setCoordinativeVariabilityOverviewAnkle_Left([...coordinativeVariabilitySeriesMeanAnkle_Left]);

      setCrpSeriesCalfFoot_Right([...meanCrpSeriesDataCalfTFootC_Right, ...meanCrpSeriesDataCalfFoot_Right]);

      setCoordinativeVariabilityOverviewAnkle_Right([...coordinativeVariabilitySeriesMeanAnkle_Right]);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [data, showAllStrides]);

  if (!data) {
    return <IonText>Data not found.</IonText>;
  } else if (loading) {
    return <IonLoading isOpen={loading} message={'Drawing graphs, one moment please...'} />;
  } else {
    return (
      <>
        <IonPopover
          cssClass=''
          event={showSettings.event}
          isOpen={showSettings.show}
          show-backdrop={false}
          onDidDismiss={() => setShowSettings({ show: false, event: undefined })}
          mode='ios'>
          <VariabilitySettingsPopoverContent
            setShowAllStrides={setShowAllStrides}
            showAllStrides={showAllStrides}
            setShowSettings={setShowSettings}
          />
        </IonPopover>

        <IonModal
          isOpen={showMoreInfoModal.show}
          onDidDismiss={() => setShowMoreInfoModal({ show: false, joint: '' })}
          css-class='ORYX_Modal_Large'
          swipeToClose={true}>
          {showMoreInfoModal.joint === 'Hip' && (
            <VariabilityModalContent
              crp_Left={crpModalSeriesHip_Left}
              crp_Right={crpModalSeriesHip_Right}
              coordinativeVariabilityData_Left={coordinativeVariabilityModalSeriesHip_Left}
              coordinativeVariabilityData_Right={coordinativeVariabilityModalSeriesHip_Right}
              setShowMoreInfoModal={setShowMoreInfoModal}
            />
          )}
          {showMoreInfoModal.joint === 'Knee' && (
            <VariabilityModalContent
              crp_Left={crpModalSeriesKnee_Left}
              crp_Right={crpModalSeriesKnee_Right}
              coordinativeVariabilityData_Left={coordinativeVariabilityModalSeriesKnee_Left}
              coordinativeVariabilityData_Right={coordinativeVariabilityModalSeriesKnee_Right}
              setShowMoreInfoModal={setShowMoreInfoModal}
            />
          )}
          {showMoreInfoModal.joint === 'Ankle' && (
            <VariabilityModalContent
              crp_Left={crpModalSeriesAnkle_Left}
              crp_Right={crpModalSeriesAnkle_Right}
              coordinativeVariabilityData_Left={coordinativeVariabilityModalSeriesAnkle_Left}
              coordinativeVariabilityData_Right={coordinativeVariabilityModalSeriesAnkle_Right}
              setShowMoreInfoModal={setShowMoreInfoModal}
            />
          )}
        </IonModal>

        <IonPage>
          <IonContent className='ion-padding' color='light'>
            <IonGrid fixed>
              <IonRow className='ion-align-items-center ion-justify-content-center third_height'>
                <IonCol size='12' className='ion-align-self-stretch'>
                  <IonCard className='ORYX_GraphCard'>
                    <IonRow className='ion-no-padding ion-align-items-center ion-justify-content-evenly fullHeight'>
                      <IonCol size='2' className='ion-align-self-stretch'>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                          <IonCol size='7' className='ion-no-padding'>
                            <p className='ORYX_GraphTitle_LeftAligned'>Hip</p>
                          </IonCol>
                          <IonCol size='5' className='ion-no-padding'>
                            <IonButtons className='ion-justify-content-end'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='variabilityCard_Header_Buttons'
                                onClick={(e: any) => {
                                  e.persist();
                                  setShowSettings({ show: true, event: e });
                                }}>
                                <IonIcon icon={settingsOutline} />
                              </IonButton>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='variabilityCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Hip' })}>
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '75%' }}>
                          <VariabilityGraphLegenda legend={pelvisLegenda} />
                        </IonRow>
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                        <VariabilityOverviewGraph
                          crpData={crpSeriesPelvisThigh_Left}
                          coordinativeVariabilityData={coordinativeVariabilityOverviewHip_Left}
                          annotations={annotations_Left}
                          highlights={highlightsHip_Left}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-self-stretch'>
                        <VariabilityOverviewGraph
                          crpData={crpSeriesPelvisThigh_Right}
                          coordinativeVariabilityData={coordinativeVariabilityOverviewHip_Right}
                          annotations={annotations_Right}
                          highlights={highlightsHip_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>

              <IonRow className='ion-align-items-center ion-justify-content-center third_height'>
                <IonCol size='12' className='ion-align-self-stretch'>
                  <IonCard className='ORYX_GraphCard'>
                    <IonRow className='ion-no-padding ion-align-items-center ion-justify-content-evenly fullHeight'>
                      <IonCol size='2' className='ion-align-self-stretch'>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                          <IonCol size='7' className='ion-no-padding'>
                            <p className='ORYX_GraphTitle_LeftAligned'>Knee</p>
                          </IonCol>
                          <IonCol size='5' className='ion-no-padding'>
                            <IonButtons className='ion-justify-content-end'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='variabilityCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Knee' })}>
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '75%' }}>
                          <VariabilityGraphLegenda legend={kneeLegenda} />
                        </IonRow>
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                        <VariabilityOverviewGraph
                          crpData={crpSeriesThighCalf_Left}
                          coordinativeVariabilityData={coordinativeVariabilityOverviewKnee_Left}
                          annotations={annotations_Left}
                          highlights={highlights_Left}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-self-stretch'>
                        <VariabilityOverviewGraph
                          crpData={crpSeriesThighCalf_Right}
                          coordinativeVariabilityData={coordinativeVariabilityOverviewKnee_Right}
                          annotations={annotations_Right}
                          highlights={highlights_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>

              <IonRow className='ion-align-items-center ion-justify-content-center third_height'>
                <IonCol size='12' className='ion-align-self-stretch'>
                  <IonCard className='ORYX_GraphCard'>
                    <IonRow className='ion-no-padding ion-align-items-center ion-justify-content-evenly fullHeight'>
                      <IonCol size='2' className='ion-align-self-stretch'>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '25%' }}>
                          <IonCol size='7' className='ion-no-padding'>
                            <p className='ORYX_GraphTitle_LeftAligned'>Ankle</p>
                          </IonCol>
                          <IonCol size='5' className='ion-no-padding'>
                            <IonButtons className='ion-justify-content-end'>
                              <IonButton
                                slot='icon-only'
                                fill='clear'
                                className='variabilityCard_Header_Buttons'
                                onClick={() => setShowMoreInfoModal({ show: true, joint: 'Ankle' })}>
                                <IonIcon icon={ORYX_MagnifyingGlassIcon} />
                              </IonButton>
                            </IonButtons>
                          </IonCol>
                        </IonRow>
                        <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '75%' }}>
                          <VariabilityGraphLegenda legend={ankleLegenda} />
                        </IonRow>
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-items-center ion-align-self-stretch'>
                        <VariabilityOverviewGraph
                          crpData={crpSeriesCalfFoot_Left}
                          coordinativeVariabilityData={coordinativeVariabilityOverviewAnkle_Left}
                          annotations={annotations_Left}
                          highlights={highlights_Left}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='5' className='ion-no-padding ion-align-self-stretch'>
                        <VariabilityOverviewGraph
                          crpData={crpSeriesCalfFoot_Right}
                          coordinativeVariabilityData={coordinativeVariabilityOverviewAnkle_Right}
                          annotations={annotations_Right}
                          highlights={highlights_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonPage>
      </>
    );
  }
};
