export {};
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonLoading,
  IonPage,
  IonRow,
  IonSearchbar,
  IonText,
  IonToast,
} from '@ionic/react';
import * as React from 'react';
import { ORYXHeader } from '../../components/menuItems/Header';
import axios from 'axios';
import { useEffect } from 'react';

export const KBHomePage = () => {
  const fetchGoogleDocsContent = async () => {
    try {
      const documentId = '1jyu0c4O7J33uLsRq-z16mfxjbv_fYinHoFWh3EGePAE';
      const clientId = '191503776578-cfog1f1b5404clp41anfqme82ougjtkd.apps.googleusercontent.com';
      const apiKey = 'AIzaSyCb-BUDov6LEnwVrtPkrrh_itrdO0G5zi4';

      // Step 1: Request user authorization
      const authResponse = await gapi.auth2.getAuthInstance().signIn({
        client_id: clientId,
        scope: 'https://www.googleapis.com/auth/documents.readonly',
      });

      // Step 2: Obtain access token
      const accessToken = authResponse.getAuthResponse().access_token;

      // Step 3: Fetch document content
      const response = await axios.get(`https://docs.googleapis.com/v1/documents/${documentId}?key=${apiKey}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const documentContent = response.data.body.content;
      console.log('document content: ', documentContent);
    } catch (error) {
      console.error('Error fetching Google Docs content:', error);
    }
  };

  const initializeGoogleApi = () => {
    return new Promise<void>((resolve, reject) => {
      gapi.load('auth2', () => {
        gapi.auth2
          .init({
            client_id: 'YOUR_GOOGLE_OAUTH_CLIENT_ID',
          })
          .then(() => {
            resolve();
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    });
  };

  useEffect(() => {
    initializeGoogleApi()
      .then(() => {
        fetchGoogleDocsContent();
      })
      .catch((error) => {
        console.error('Error initializing Google API:', error);
      });
  }, []);

  return (
    <IonPage>
      <ORYXHeader />
      <IonContent className='ion-padding' id='main' color='light'>
        <IonGrid fixed>I need some content!</IonGrid>
      </IonContent>
    </IonPage>
  );
};
