import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { IonButton, IonCard, IonCol, IonItem, IonList, IonRow, IonText } from '@ionic/react';
import { colors } from '.';
import { useEffect, useState } from 'react';

type VariabilityGraphLegendaProps = {
  legend: any;
};

export const VariabilityGraphLegenda = ({ legend }: VariabilityGraphLegendaProps) => {
  const [legendItems, setLegendItems] = useState<any>(legend);

  useEffect(() => {
    console.log('useEffect legend');
    setLegendItems(legend);

    return () => {
      console.log('useEffect legend cleanup');
    };
  }, [legend]);
  return (
    <>
      <div className=''>
        {legend.map((element: any, key: any) => {
          const color = element.color as string;
          const styles = {
            backgroundColor: color,
          };
          if ('side' in element) {
            return (
              <IonRow className='legend_entry' key={element.name}>
                <div className='legend_dot' style={styles} />
                {/* <div className='legend_dot' style={styles} /> */}
                <p className=' legend_name'>
                  {element.name} - {element.side}
                </p>
              </IonRow>
            );
          } else {
            return (
              <>
                <IonRow className='legend_entry' key={element.name}>
                  <div className='legend_dot' style={styles} />
                  <p className='legend_name'>{element.name}</p>
                </IonRow>
              </>
            );
          }
        })}
      </div>
    </>
  );
};
