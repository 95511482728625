import React from 'react';
import {
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSlide,
  IonSlides,
  IonIcon,
} from '@ionic/react';
import './modalindex.css';
import IBimagePelvicobliquity1 from './assets/IBimagePelvicobliquity1.png';
import IBimagePelvicobliquity2 from './assets/IBimagePelvicobliquity2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  direction: 'vertical',
};

export const ModalPelvicObliquity: React.FC = () => {
  return (
    <>
      <IonCardHeader className='reportInfoModal_Header'>
        <div>
          <IonCardTitle className='reportInfoModal_Title'>Pelvic obliquity</IonCardTitle>
        </div>
      </IonCardHeader>

      <IonSlides pager={true} options={slideOpts} mode='ios'>
        <IonSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimagePelvicobliquity1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  At initial contact the position of it is almost neutral (0° obliquity). Throughout loading response,
                  the hip of the trailing leg begins to drop, increasing the magnitude of pelvic obliquity. Hip
                  abductors become active to halt the contralateral pelvic drop.
                  <br></br>
                  <br></br>
                  After midstance, the motion of the pelvis is reversed, with the swing-side hip lifting to regain
                  neutral position again. This movement is called ‘hike’. People with a normal healthy gait, typically
                  exhibit 5° to 7° of pelvic obliquity towards each side. The magnitude of pelvic obliquity increases
                  linearly with walking speed.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>

        <IonSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimagePelvicobliquity2} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  Since the weight of the upper body acts downward through the pelvis, control of pelvic motion is vital
                  to maintaining whole body balance in the coronal plane.
                  <br></br>
                  <br></br>
                  Excessive pelvic drop (Trendelenburg sign) is due to weakness of the hip abductors on the supporting
                  leg side.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>
      </IonSlides>
    </>
  );
};
