import React from 'react';
import {
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSlide,
  IonSlides,
  IonCardSubtitle,
  IonIcon,
} from '@ionic/react';
import './modalindex.css';
import IBimageKneestabilityatloadingresponse1 from './assets/IBimageKneestabilityatloadingresponse1.png';
import IBimageKneestabilityatloadingresponse2 from './assets/IBimageKneestabilityatloadingresponse2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  direction: 'vertical',
};

export const ModalKneeStabilityLoadingResponse: React.FC = () => {
  return (
    <>
      <IonCardHeader className='reportInfoModal_Header'>
        <div>
          <IonCardTitle className='reportInfoModal_Title'>Knee Stability</IonCardTitle>
          <IonCardSubtitle className='reportInfoModal_Subtitle'>Loading response</IonCardSubtitle>
        </div>
      </IonCardHeader>

      <IonSlides pager={true} options={slideOpts} mode='ios'>
        <IonSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageKneestabilityatloadingresponse1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  After initial contact the leg has to accept the body weight. The load increases until the point of
                  single limb support (carrying the full body weight) and midstance. To absorb the shock and establish a
                  good balance, all joints contribute by flexing slightly.
                  <br></br>
                  <br></br>
                  In the frontal plane, hip abductors, <i>tensor fascia latae</i> and upper portions of the{' '}
                  <i>m. gluteus maximus</i> control drop of the contralateral pelvis (this effectively is relative hip
                  adduction). The anterior gluteals move the hip joint into internal rotation, rotating the pelvis
                  slightly forward on the opposite side.
                  <br></br>
                  <br></br>
                  In the chain pelvis – hip – knee – ankle, the knee is the weakest link. This is not only due to its
                  relatively unstable architecture; the way it functions largely depends on how the adjacent joints
                  behave.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>

        <IonSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageKneestabilityatloadingresponse2} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  Throughout Loading Response, the pressure on the tibia plateau (and the menisci) should be increased
                  evenly. When hip and or ankle fail, this will immediately impact the knee joint. As a result distorted
                  patterns will appear in all anatomical planes (also see: varus valgus).
                  <br></br>
                  <br></br>
                  <strong>Causes poor stability</strong>
                  <br></br>
                  Poor stability is typically due to:
                  <ul>
                    <li>Weak quadriceps</li>
                    <li>Fear</li>
                    <li>Pain</li>
                    <li>Excessive stride length</li>
                    <li>Too high heels (inlays)</li>
                  </ul>
                  <strong>Additional analysis</strong>
                  <br></br>
                  The knee should be extended properly in anticipation to Initial Contact (approximately &lt; 10°
                  flexion). Poor knee extension can be due to a weak <i>m. vastus medialis</i>, flexion contracture, or
                  difference in limb length.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>
      </IonSlides>
    </>
  );
};
