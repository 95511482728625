import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './graphCard.css';
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonRow } from '@ionic/react';
import { colors } from '../CustomGraphComponents';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';

type StanceLineGraphProps = {
  series: any;
  title: string;
  ICAnnotation: any;
  MStAnnotation: any;
  HOAnnotation: any;
  TOAnnotation: any;
  yAxisMin: number;
  yAxisMax: number;
};

export const StanceLineGraph = ({
  series,
  title,
  ICAnnotation,
  MStAnnotation,
  HOAnnotation,
  TOAnnotation,
  yAxisMin,
  yAxisMax,
}: StanceLineGraphProps) => {
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = React.useState('small');

  React.useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
    }
  }, [width]);
  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      offsetX: -10,
    },
    xaxis: {
      type: 'numeric',
      labels: {
        show: false,
        style: {
          fontSize: '8px',
        },
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: yAxisMin,
      max: yAxisMax,
      forceNiceScale: true,
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
    },
    // title: {
    //   text: title,
    //   align: 'center',
    //   style: {
    //     fontSize: '14px',
    //     fontWeight: 'bold',
    //     fontFamily: 'Montserrat',
    //     color: '#2066FA',
    //   },
    // },

    stroke: {
      width: [1],
      dashArray: [0],
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    colors: colors,
    annotations: {
      xaxis: [
        {
          x: 0,
          borderColor: '#c2c2c2',
          label: {
            text: 'TSw',
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: ICAnnotation.x,
          borderColor: '#c2c2c2',
          label: {
            text: ICAnnotation.title,
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: MStAnnotation.x,
          borderColor: '#c2c2c2',
          label: {
            text: MStAnnotation.title,
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: HOAnnotation.x,
          borderColor: '#c2c2c2',
          label: {
            text: HOAnnotation.title,
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
        {
          x: TOAnnotation.x,
          borderColor: '#c2c2c2',
          label: {
            text: TOAnnotation.title,
            borderColor: 'transparent',
            position: 'bottom',
            orientation: 'horizontal',
            offsetY: 10,
            style: {
              background: 'transparent',
              fontSize: '8px',
              fontFamily: 'Montserrat',
            },
          },
        },
      ],
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <IonCard className='ORYX_GraphCard'>
      <IonCardHeader className='attractorCard_Header' style={{ height: '35px' }}>
        <IonGrid fixed className='ion-no-padding '>
          <IonRow className='ion-no-padding ion-align-items-center' style={{ paddingTop: '5px' }}>
            <IonCol size='9' className=''>
              <IonCardTitle className='attractorCard_Title'>{title}</IonCardTitle>
            </IonCol>

            <IonCol size='3' className='ion-justify-content-center'>
              {/* <IonButtons className='ion-justify-content-center'>
                            <IonButton
                              slot='icon-only'
                              fill='clear'
                              className='attractorCard_Header_Buttons'
                              onClick={() => setShowModal(true)}>
                              <IonIcon icon={informationCircleOutline} />
                            </IonButton>
                          </IonButtons> */}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardHeader>
      <IonCardContent
        className=''
        style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', height: '85%' }}>
        <IonRow className='ion-align-items-center full_height'>
          <div className='' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Chart
              options={options}
              series={series}
              type='line'
              width='100%'
              height={windowSize == 'small' ? 125 : 175}
            />
          </div>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};
