import {
  IonModal,
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonButtons,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
} from '@ionic/react';
import { closeCircleOutline, informationCircleOutline } from 'ionicons/icons';
import { useState } from 'react';
import { NameField_v2, VarusValgusField, VarusValgusLabel } from '../Fields';

import './cards.css';

type AttractorCardProps = {
  title: string;
  subtitle?: string;
  info: React.ReactNode;
  img: string;
  labels?: string[];
  attractorRow: number[];
  kpiLabels?: string[];
  varusValgus?: string[];
};

/** Card in the analysis page to show the avatar and attractor field
 * @param img - The avatar image
 * @param labels - Attractor labels for the status field, tuple of (Left, Right) values
 */
export const AttractorCard = ({
  title,
  subtitle,
  info,
  img,
  labels,
  kpiLabels,
  attractorRow,
  varusValgus,
}: AttractorCardProps) => {
  const [showModal, setShowModal] = useState(false);

  const error = attractorRow[0] >= 2 || attractorRow[1] >= 2 ? 'error' : '';
  const cardClassName = error ? 'attractorCard_Error' : 'attractorCard';
  const kpiLabel = (labels: string[], kpi: number) => {
    return labels[kpi - 1];
  };

  return (
    <>
      <IonModal isOpen={showModal} cssClass='ORYX_Modal_Large' onDidDismiss={() => setShowModal(false)}>
        {info}
        <IonButton slot='icon-only' onClick={() => setShowModal(false)} fill='clear' className='ORYX_ModalButton_Close'>
          <IonIcon icon={closeCircleOutline} />
        </IonButton>
      </IonModal>

      <IonCard className={cardClassName}>
        <IonCardHeader className='attractorCard_Header'>
          <IonGrid fixed className='ion-no-padding'>
            <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row'>
              <IonCol size='9' className=''>
                <IonCardTitle className='attractorCard_Title'>{title}</IonCardTitle>
                {subtitle && <IonCardSubtitle className='attractorCard_Subtitle'>{subtitle}</IonCardSubtitle>}
              </IonCol>

              <IonCol size='3' className='ion-justify-content-center'>
                <IonButtons className='ion-justify-content-center'>
                  <IonButton
                    slot='icon-only'
                    fill='clear'
                    className='attractorCard_Header_Buttons'
                    onClick={() => setShowModal(true)}>
                    <IonIcon icon={informationCircleOutline} />
                  </IonButton>
                </IonButtons>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardHeader>

        <IonCardContent className='full_height'>
          <IonGrid class='ion-no-padding' fixed>
            <IonRow className='full_width ion-justify-content-center'>
              <IonCol>
                <IonImg src={img} class='attractorCard_Avatar_Run' />
              </IonCol>
            </IonRow>
            {labels && (
              <IonRow className='full_width ion-justify-content-center'>
                <IonCol size='6' className='border_Right'>
                  <NameField_v2 name='Left' value={labels[0]} />
                </IonCol>
                <IonCol size='6'>
                  <NameField_v2 name='Right' value={labels[1]} />
                </IonCol>
              </IonRow>
            )}
            {kpiLabels && !varusValgus && (
              <IonRow className='full_width ion-justify-content-center'>
                <IonCol size='6' className='border_Right'>
                  <NameField_v2 name='Left' value={kpiLabel(kpiLabels, attractorRow[0])} />
                </IonCol>
                <IonCol size='6'>
                  <NameField_v2 name='Right' value={kpiLabel(kpiLabels, attractorRow[1])} />
                </IonCol>
              </IonRow>
            )}
            {varusValgus && kpiLabels && (
              <IonRow className='full_width ion-justify-content-center'>
                <IonCol size='6' className='border_Right'>
                  <VarusValgusField name='Left' value={kpiLabel(kpiLabels, attractorRow[0])} label={varusValgus[0]} />
                </IonCol>
                <IonCol size='6'>
                  <VarusValgusField name='Right' value={kpiLabel(kpiLabels, attractorRow[1])} label={varusValgus[1]} />
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};
