import React from 'react';
import {
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSlides,
  IonSlide,
  IonIcon,
} from '@ionic/react';
import './modalindex.css';
import IBimagehiplock1 from './assets/IBimagehiplock1.png';
import IBimagehiplock2 from './assets/IBimagehiplock2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  direction: 'vertical',
};

export const ModalHipLock: React.FC = () => {
  return (
    <>
      <IonCardHeader className='reportInfoModal_Header'>
        <div>
          <IonCardTitle className='reportInfoModal_Title'>Hip Lock</IonCardTitle>
        </div>
      </IonCardHeader>

      <IonSlides pager={true} options={slideOpts} mode='ios'>
        <IonSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='8' size-xl='8'>
                <div>
                  <IonImg src={IBimagehiplock1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  Towards the end of leg extension the hip of the supporting leg will go into abduction, lifting the
                  free side of the pelvis (swing leg). The pelvis will also rotate slightly forward. This{' '}
                  <i>hip lock</i> is a result of co-contractions (feedforward) of all muscles in the hip area to protect
                  the joint.
                  <br></br>
                  <br></br>
                  Hip abduction will also increase tension in the iliotibial band which is fully stretched by the
                  contraction of the <i>m. gluteus maximus</i>. This in turn results in a lateral force which widens the
                  medial interarticular space of the knee. This force has the opposite effect to gravity, which tries to
                  widen the lateral interarticular space. A proper execution of hip lock results in an equal
                  distribution of forces over the tibia plateau/menisci.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>

        <IonSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='8' size-xl='8'>
                <div>
                  <IonImg src={IBimagehiplock2} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  Without raising the free pelvic side, running economy will be much worse. In case of a hip drop
                  (lowering of the pelvis), endorotation of the supporting leg will become problematic and stability of
                  the knees will be much lower.
                  <br></br>
                  <br></br>
                  Hip drop typically leads to kneeing in, increasing tension on all kinds of tissues.
                  <br></br>
                  <br></br>
                  The lack of tension in the iliotibial band will widen the lateral interarticular space, putting much
                  stress on the medial meniscus.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>
      </IonSlides>
    </>
  );
};
