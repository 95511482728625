/** Load (and keep listening) all report entries for a given user from the DB */
import firebase from 'firebase';
import 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { useCollectionData, useDocument, useDocumentData } from 'react-firebase-hooks/firestore';
import { ReportData, ReportDict, ReportId, ReportInfo, ReportInfoDict, UserId } from '../../model';
import { myFirebase } from '../firebaseConfig';

const db = myFirebase.firestore();

type ReportDataDict = {
  [id in ReportId]: ReportData;
};

export type UseReportsHook = {
  reportDict: ReportDict;
  isLoading: boolean;
  isError: Error | undefined;
  dataReady: boolean;
  clearError: () => void;
  doDelete: (reportId: string) => Promise<void>;
  getReportData: (reportId: string) => Promise<any>;
  data: any;
};

export const useReports = (userId: UserId, reportInfoDict: ReportInfoDict): UseReportsHook => {
  const userRef = db.collection('users').doc(userId);
  const reportsRef = userRef.collection('reports');
  const [reportDocs, loading, error] = useCollectionData<ReportData>(reportsRef, { idField: 'id' });
  const [isError, setIsError] = useState<Error | undefined>();
  const [data, setData] = useState();
  const [dataReady, setDataReady] = useState<boolean>(false);

  const doDelete = async (reportId: string) => {
    const reportInfoDelete = await userRef.collection('reportInfo').doc(reportId);
    reportInfoDelete
      .update({ status: 'deleted' })
      .then((_doc) => {
        console.log('status changed to deleted!');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getReportData = async (reportInfoId: string) => {
    setDataReady(false);
    setData(undefined);
    // Get the reportId from the reportInfo collection
    const reportInfoRef = userRef.collection('reportInfo').doc(reportInfoId);
    const reportInfo = await reportInfoRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log('Document data:', doc.data());
          return doc.data();
        } else {
          console.log('No such document!');
        }
      })
      .catch((error) => {
        setIsError(error);
      });

    if (reportInfo) {
      const reportType: number = reportInfo.reportType;

      const results = await getData(reportType, reportInfo, userRef)
        .then((results) => {
          return results;
        })
        .catch((error) => {
          setIsError(error);
        });
      setData(results);
      setDataReady(true);
    }
  };

  // Called to close the error Toast
  const clearError = () => {
    setIsError(undefined);
  };

  if (error) {
    setIsError(error);
  }

  const convertReports = (): ReportDict => {
    if (!reportDocs) {
      return {};
    }
    const reportDataDict: ReportDataDict = reportDocs.reduce(
      (reportDataDict: ReportDataDict, reportData: ReportData) => {
        reportDataDict[reportData.id] = reportData;
        return reportDataDict;
      },
      {},
    );
    return Object.values(reportInfoDict).reduce((reportDict: ReportDict, reportInfo: ReportInfo) => {
      const reportId = reportInfo.reportId;
      if (!reportId) {
        return reportDict;
      }

      const reportData = reportDataDict[reportId];
      if (!reportData) {
        return reportDict;
      }
      reportDict[reportId] = {
        ...reportInfo,
        data: reportData,
      };
      return reportDict;
    }, {});
  };

  const reportDict = useMemo(convertReports, [userId, reportInfoDict, reportDocs]);
  const hook: UseReportsHook = {
    reportDict: reportDict,
    isLoading: loading,
    isError: isError,
    clearError: clearError,
    doDelete: doDelete,
    getReportData: getReportData,
    dataReady: dataReady,
    data: data,
  };
  return useMemo(() => hook, [reportDict, loading, isError, dataReady, data]);
};

async function getData(
  reportType: number,
  reportInfo: firebase.firestore.DocumentData,
  userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
) {
  const promises = [];
  const collectionMappings: any = {
    1: {
      reportId: 'reports',
      stridesLeft: 'strideData_Left',
      stridesCoronalLeft: 'strideData_Left_Coronal',
      stridesTransversalLeft: 'strideData_Left_Transversal',
      stridesRight: 'strideData_Right',
      stridesCoronalRight: 'strideData_Right_Coronal',
      stridesTransversalRight: 'strideData_Right_Transversal',
      coordinativeVariability: 'coordinativeVariabilityData',
      coordinativeVariabilityPlanes: 'coordinativeVariabilityData_Planes',
      crpHipLeft: 'crpData_Hip_Left',
      crpHipRight: 'crpData_Hip_Right',
      crpKneeLeft: 'crpData_Knee_Left',
      crpKneeRight: 'crpData_Knee_Right',
      crpAnkleLeft: 'crpData_Ankle_Left',
      crpAnkleRight: 'crpData_Ankle_Right',
      crpMean: 'crpData_Mean',
      fileId: 'fileId',
    },
    2: {
      reportId: 'reports',
      stridesLeft: 'strideData_Left',
      stridesCoronalLeft: 'strideData_Left_Coronal',
      stridesTransversalLeft: 'strideData_Left_Transversal',
      stridesRight: 'strideData_Right',
      stridesCoronalRight: 'strideData_Right_Coronal',
      stridesTransversalRight: 'strideData_Right_Transversal',
      coordinativeVariability: 'coordinativeVariabilityData',
      coordinativeVariabilityStance: 'coordinativeVariabilityData_Stance',
      coordinativeVariabilityPlanes: 'coordinativeVariabilityData_Planes',
      crpHipLeft: 'crpData_Hip_Left',
      crpHipRight: 'crpData_Hip_Right',
      crpKneeLeft: 'crpData_Knee_Left',
      crpKneeRight: 'crpData_Knee_Right',
      crpAnkleLeft: 'crpData_Ankle_Left',
      crpAnkleRight: 'crpData_Ankle_Right',
      crpMean: 'crpData_Mean',
      fileId: 'fileId',
    },
    3: {
      reportId: 'reports',
      stridesLeft: 'strideData_Left',
      stridesCoronalLeft: 'strideData_Left_Coronal',
      stridesTransversalLeft: 'strideData_Left_Transversal',
      stridesRight: 'strideData_Right',
      stridesCoronalRight: 'strideData_Right_Coronal',
      stridesTransversalRight: 'strideData_Right_Transversal',
      coordinativeVariability: 'coordinativeVariabilityData',
      coordinativeVariabilitySquat: 'coordinativeVariabilityData_Squat',
      crpHipLeft: 'crpData_Hip_Left',
      crpHipRight: 'crpData_Hip_Right',
      crpKneeLeft: 'crpData_Knee_Left',
      crpKneeRight: 'crpData_Knee_Right',
      crpAnkleLeft: 'crpData_Ankle_Left',
      crpAnkleRight: 'crpData_Ankle_Right',
      fileId: 'fileId',
    },
    4: {
      reportId: 'reports',
      stridesLeft: 'strideData_Left',
      stridesCoronalLeft: 'strideData_Left_Coronal',
      stridesTransversalLeft: 'strideData_Left_Transversal',
      stridesRight: 'strideData_Right',
      stridesCoronalRight: 'strideData_Right_Coronal',
      stridesTransversalRight: 'strideData_Right_Transversal',
      coordinativeVariability: 'coordinativeVariabilityData',
      coordinativeVariabilitySquat: 'coordinativeVariabilityData_Squat',
      crpHipLeft: 'crpData_Hip_Left',
      crpHipRight: 'crpData_Hip_Right',
      crpKneeLeft: 'crpData_Knee_Left',
      crpKneeRight: 'crpData_Knee_Right',
      crpAnkleLeft: 'crpData_Ankle_Left',
      crpAnkleRight: 'crpData_Ankle_Right',
      fileId: 'fileId',
    },
  };
  const collectionKeys = Object.keys(collectionMappings[reportType]);

  for (const [key, value] of Object.entries(reportInfo)) {
    if (collectionKeys.includes(key)) {
      if (key === 'fileId') {
        const collectionRef = userRef.collection('kinematicsFiles');
        const snapshot = await collectionRef.doc(reportInfo[key]).get();
        promises.push({ [key]: snapshot.data() });
        continue;
      } else {
        const collectionName = collectionMappings[reportType][key];
        const collectionRef = userRef.collection(collectionName);
        const snapshot = await collectionRef.doc(reportInfo[key]).get();
        promises.push({ [key]: snapshot.data() });
      }
    }
  }

  const results = Object.assign({}, ...promises);
  return results;
}
