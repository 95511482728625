import React from 'react';
import { IonCardHeader, IonCardTitle, IonGrid, IonRow, IonCol, IonImg, IonSlide, IonSlides } from '@ionic/react';
import './modalindex.css';
import IBimageVarusValgus from './assets/IBimageVarusValgus.png';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  direction: 'vertical',
};

export const ModalVarusValgus: React.FC = () => {
  return (
    <>
      <IonCardHeader className='reportInfoModal_Header'>
        <div>
          <IonCardTitle className='reportInfoModal_Title'>Varus / Valgus</IonCardTitle>
        </div>
      </IonCardHeader>

      <IonSlides pager={false} options={slideOpts} mode='ios'>
        <IonSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageVarusValgus} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  Throughout loading response, alignment of the upper and lower leg should remain neutral (zero degrees)
                  to ensure a controlled increase level of pressure on the tibial plateau and menisci. The alignment is
                  visualized by the black perpendicular line.
                  <br></br>
                  <br></br>
                  When a negative angle occurs (&lt; -10°), the condition is assessed as valgus. A positive angle is
                  qualified as varus (&gt; 10°).
                  <br></br>
                  <br></br>
                  <strong>Potential causes</strong>
                  <br></br>
                  <ul>
                    <li>Poor abductor strength</li>
                    <li>Poor gluteal strength</li>
                    <li>Arthritis</li>
                    <li>Weak knee ligaments</li>
                  </ul>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>
      </IonSlides>
    </>
  );
};
