/** Reports page, showing metadata for available reports for the current user */
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonLoading,
  IonPage,
  IonRow,
  IonSearchbar,
  IonToast,
} from '@ionic/react';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { ORYXHeader } from '../../components/menuItems/Header';
import { useReportContext } from '../../components/reportContext';
import { useSearch } from '../../hooks/useSearch';
import { ReportInfo, ReportInfoDict } from '../../model';
import { ReportCard } from './ReportCard';
import { analytics } from '../../hooks/firebaseConfig';

/** Map over reports sorted by uploadTimestamp */
const sortReportInfos = (reportInfoDict: ReportInfoDict): ReportInfo[] => {
  // Create array of tuples (id, reportInfo)
  const items: [string, ReportInfo][] = Object.keys(reportInfoDict).map((id: string) => {
    return [id, reportInfoDict[id]];
  });

  // Sort the array based on the second element (reportInfo.uploadTimestamp)
  const reportsSorted = items.sort((first, second) => {
    const a = second[1].uploadTimestamp.getTime();
    const b = first[1].uploadTimestamp.getTime();
    return a - b;
  });

  return reportsSorted.map(([_id, reportInfo]) => reportInfo);
};

type ReportsPageProps = {
  filteroption?: string;
};
/** Reports page, showing all reports (metadata) available for the current user
 * Loading reportInfo data from the database using the useReportInfo hook.
 */
export const ReportsPage = ({ filteroption }: ReportsPageProps) => {
  const historyHook = useHistory();
  const { reportInfoDict, isLoading, isError, clearError } = useReportContext();
  const reportId = useMemo(() => Object.keys(reportInfoDict), [reportInfoDict]);

  // Get the reportIds from every id in the reportInfoDict
  const reportIds = Object.entries(reportInfoDict).map(([id, reportInfo]) => {
    return reportInfo.reportId;
  });

  const [searchOptions, setSearchOptions] = React.useState({
    includeScore: true,
    useExtendedSearch: true,
    keys: ['filename', 'tags', 'reportType'],
  });

  console.log('reportIds: ', reportIds);

  const list = useMemo(() => sortReportInfos(reportInfoDict), [reportInfoDict]);
  const { onSearch, query, searchResult } = useSearch(searchOptions, list);

  useEffect(() => {
    if (history.state.state !== undefined) {
      analytics.logEvent('search_report');
      setSearchOptions({
        includeScore: true,
        useExtendedSearch: true,
        keys: ['reportType'],
      });
      onSearch(history.state.state['filterOption']);
    }
  }, [history]);

  return (
    <IonPage>
      <ORYXHeader loading={isLoading === 'reportInfo'} />
      <IonContent className='ion-padding' id='main' color='light'>
        <IonGrid fixed>
          <IonRow>
            <IonCol class='ion-padding'>
              <IonButton
                className='oryx_button_small'
                onClick={() => {
                  historyHook.push('/upload');
                }}>
                Upload Files
              </IonButton>
            </IonCol>

            <IonCol className='ion-align-self-center'>
              <IonSearchbar value={query} onIonChange={(e) => onSearch(e)} showCancelButton='focus'></IonSearchbar>
            </IonCol>
          </IonRow>
        </IonGrid>
        {isLoading ? (
          <IonLoading cssClass='my-custom-class' isOpen={!isLoading} message={'Fetching data please wait...'} />
        ) : (
          <IonGrid fixed>
            <IonRow>
              {reportInfoDict && (
                <>
                  {searchResult.map((reportInfo) => {
                    return (
                      <IonCol size='12' size-md='6' key={reportInfo.id}>
                        <ReportCard reportInfo={reportInfo} />
                      </IonCol>
                    );
                  })}
                </>
              )}
            </IonRow>
          </IonGrid>
        )}
      </IonContent>

      {/* Show errors, if any, as "toast" below the page */}
      <IonToast
        isOpen={isError ? true : false}
        onDidDismiss={() => clearError()}
        message={isError && isError.message}
        color='danger'
        position='bottom'
        buttons={[
          {
            text: 'Close',
            role: 'cancel',
          },
        ]}
      />
    </IonPage>
  );
};
