import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonModal,
  IonRow,
  IonSpinner,
  IonText,
} from '@ionic/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FileInput } from './fileInput';
import './index.css';

import { closeCircleOutline, trashBinOutline } from 'ionicons/icons';

// Custom Icons
// import imageUploadIcon from '../assets/icons/imageUploadIcon.svg';
// import magnifyingGlassPlusIcon from '../assets/icons/magnifyingGlassPlusIcon.svg';
// import videoUploadIcon from '../assets/icons/videoUploadIcon.svg';
import fileUploadIcon from '../../assets/icons/fileUploadIcon.svg';
import csvFileIcon from '../../assets/icons/csvFileIcon.svg';

export interface IUploadAreaProps {
  onChange?: ((event: any) => void) | undefined;
  onFileRemove?: ((event: any) => void) | undefined;
}
export const UploadArea = ({ onChange, onFileRemove }: IUploadAreaProps) => {
  // const { dataResponse, downloadURL, isLoading, isError, errorMessage, progress, setUploadData, clearError } =
  //   useFirebaseUpload();
  // const user = useAuthContext().state.userState?.user;
  // const { doDeleteMedia, isDeleted, isExerciseError, exerciseErrorMessage } = useExercises(user?.uid);
  const [file, setFile] = useState('');
  // const [fileName, setFileName] = useState<string>('');
  // const [contentType, setContentType] = useState<string>('');
  // const [data, setData] = useState<typeof dataResponse>();
  // const [uuid, setUuid] = useState<string>('');
  // const [contentURL, setContentURL] = useState<string>('');

  const handleFileChange = (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const filename = e.target.files[0].name;
      setFile(filename);
      onChange && onChange(e);
    }
  };

  // drag state
  const [dragActive, setDragActive] = React.useState(false);

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
      handleFileChange(e);
    }
  };

  return (
    <>
      <IonGrid fixed className={dragActive ? 'drag-active' : 'uploadArea_box'}>
        <>
          <div
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}>
            {!file ? (
              <>
                <IonRow className='ion-justify-content-center '>
                  <IonIcon src={fileUploadIcon} className='uploadIcon' />
                </IonRow>
                <>
                  <IonRow className='ion-justify-content-center '>
                    <IonText>
                      <FileInput onChange={handleFileChange} />
                    </IonText>
                  </IonRow>
                  <IonRow className='ion-justify-content-center '>
                    <IonText>
                      <small>.csv file only</small>
                    </IonText>
                  </IonRow>
                </>
              </>
            ) : (
              <>
                <IonRow className='ion-justify-content-center '>
                  <IonIcon src={csvFileIcon} className='uploadIcon' />
                </IonRow>
                <IonRow>
                  <IonText>
                    <p>{file}</p>
                  </IonText>
                </IonRow>
                <IonRow className='ion-justify-content-center'>
                  <IonCol className='ion-justify-content-center'>
                    <IonButton
                      fill='clear'
                      size='small'
                      expand='full'
                      className='errorIcon'
                      onClick={(e) => {
                        console.log('click!');
                        setFile('');
                        onFileRemove && onFileRemove(e);
                      }}>
                      <IonIcon src={trashBinOutline} slot='icon-only' />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </>
            )}
          </div>
        </>
      </IonGrid>
    </>
  );
};
