// Ionic imports
import { IonCol, IonIcon, IonImg, IonRow } from '@ionic/react';

// Custom imports
import { MappedSegmentImages } from './SensorImages';
import { SensorBatteryInd } from './SensorBatteryInd';
import './Sensor.css';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';

interface SensorProps {
  sensorOrientation: number;
  batteryLevel: number;
  charging: boolean;
  mappedSegment: string;
  enabled: boolean;
  dataRate: number;
}
export const Sensor = ({
  sensorOrientation,
  batteryLevel,
  charging,
  mappedSegment,
  enabled,
  dataRate,
}: SensorProps) => {
  return (
    <IonCol size='3' className='animate-sensor-pop-in'>
      <IonRow>
        <IonCol size='12'>
          <IonImg
            src={MappedSegmentImages[mappedSegment]}
            className='sensorImage'
            style={{ transform: `rotate(${sensorOrientation}deg)` }}
          />
          {enabled && (
            <IonIcon
              src={sensorOrientation == 0 ? closeOutline : checkmarkOutline}
              className={sensorOrientation == 0 ? 'noData' : 'data'}
            />
          )}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='12' className='content_center_col ion-justify-content-center'>
          <SensorBatteryInd batteryLevel={batteryLevel} charging={charging} />
          {~~dataRate}
        </IonCol>
      </IonRow>
    </IonCol>
  );
};

interface ScannedSensorProps {
  mappedSegment: string;
}

export const ScannedSensor = ({ mappedSegment }: ScannedSensorProps) => {
  return (
    <IonCol size='3' className='animate-sensor-pop-in'>
      <IonRow>
        <IonCol size='12'>
          <IonImg src={MappedSegmentImages[mappedSegment]} className='scannedSensorImage' />
        </IonCol>
      </IonRow>
    </IonCol>
  );
};
