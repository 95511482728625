import React from 'react';
import {
  IonCardHeader,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSlide,
  IonSlides,
  IonCardSubtitle,
  IonIcon,
} from '@ionic/react';
import './modalindex.css';
import IBimageKneeExtensionTerminalStance1 from './assets/IBimageKneeExtensionTerminalStance1.png';
import IBimageKneeExtensionTerminalStance2 from './assets/IBimageKneeExtensionTerminalStance2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  direction: 'vertical',
};

export const ModalKneeExtensionTerminalStance = () => {
  return (
    <>
      <IonCardHeader className='reportInfoModal_Header'>
        <div>
          <IonCardTitle className='reportInfoModal_Title'>Knee extension</IonCardTitle>
          <IonCardSubtitle className='reportInfoModal_Subtitle'>Terminal stance</IonCardSubtitle>
        </div>
      </IonCardHeader>

      <IonSlides pager={true} options={slideOpts} mode='ios'>
        <IonSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageKneeExtensionTerminalStance1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  After midstance the bodyweight will be in front of the foot. The changing direction of ground reaction
                  forces initiate a knee-extensor moment. How large this moment (and therefore knee extension) is, to a
                  large extent depends on the amount of hip extension.
                  <br></br>
                  <br></br>
                  Knee extension at the end of terminal stance is part of the proximodistal sequence (also see: hip
                  extension at terminal stance) and is an important prerequisite initiating the push off by ankle
                  plantar flexion.
                  <br></br>
                  <br></br>
                  This will also activate the abductor muscles which stabilize the pelvis in preparation to initial
                  contact of the opposite leg when the bodyweight will be shifted laterally.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>

        <IonSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageKneeExtensionTerminalStance2} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  If the knee does not extend sufficiently, forward propulsion will not be powerful. As a result the
                  knee angle at toe off can become too low (&lt; 40°) which will negatively effect the entire leg swing.
                  <br></br>
                  <br></br>
                  Poor knee extension can be due to:
                  <ul>
                    <li>Slow walking speed</li>
                    <li>Limited knee mobility</li>
                  </ul>
                  <br></br>
                  When not extending properly, the hamstrings will not be brought to optimal length. Therefor they can
                  become tight, resulting in problems around the lower back area.
                  <br></br>
                  <br></br>
                  <strong>Additional analysis</strong>
                  <br></br>
                  Poor knee extension can lead to poor pelvic control (perturbated) and or high range of motion of
                  pelvic tilt (&gt; 10°), poor plantar flexion range at toe off (&gt; -10° - i.e. less than ten degrees
                  plantar flexion).
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>
      </IonSlides>
    </>
  );
};
