/** The Run Kinematics report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonItem,
  IonIcon,
  IonChip,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonLoading,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { Report, RunReportData, StridesData } from '../../../model';
import walker from '../../../assets/icons/Squat.png';
import { personOutline, pricetagsOutline } from 'ionicons/icons';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import {
  JointCouplingGraph,
  ScissorsColumnChart,
  ScissorsGraphCard,
  StanceCoordinativeVariabilityGraph,
  StanceLineGraph,
  ThighBarGraph,
} from '../components/Graphs';
import {
  toGraphData,
  toScissorsData,
  flattenData,
  toJointCouplingData,
  toScatterData,
  toVariabilityData,
  toLineGraphDataStrides,
  calculateSymmetry,
} from '../components/DataFunctions';
import {
  colorCS,
  colorLeft,
  colorRight,
  colors,
  variabilityAll_Left,
  variabilityAll_Right,
  variabilityMean_Left,
  variabilityMean_Right,
  variabilitySelected_Left,
  variabilitySelected_Right,
} from '../components/CustomGraphComponents';

import '../components/Graphs/graphCard.css';
import ReactApexChart from 'react-apexcharts';
import { DashboardCard } from '../components/Cards';
import { useMemo, useState } from 'react';

type AttractorsReportProps = RouteComponentProps & {
  report: RunReportData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunScissorsReportTab = ({ report }: AttractorsReportProps) => {
  const [loading, setLoading] = useState(true);
  const data = report as RunReportData | undefined;
  const colorsScissorsLeft = ['#AAFF49', '#0552F3', '#E81A1A']; // green, blue, red
  const colorsScissorsRight = ['#0552F3', '#AAFF49', '#E81A1A']; // blue, green, red
  const colorsVariabilityLeft = ['#A5C1FD', '#8B5CF6', '#03308F'];
  const colorsVariabilityRight = ['#6EE7B7', '#2066FA', '#064E3B'];

  //#region Get the data for the graphs
  // Define the data for the Scissors graphs
  const rightThigh_LeftStrides = useMemo(
    () => toGraphData(data?.stridesLeft.rightThigh_LeftStrides),
    [data?.stridesLeft.rightThigh_LeftStrides],
  );
  const leftThigh = useMemo(() => toGraphData(data?.stridesLeft.leftThigh), [data?.stridesLeft.leftThigh]);
  const leftScissorsMoments = useMemo(
    () => toScissorsData(data?.reportId.scissors_Left),
    [data?.reportId.scissors_Left],
  );
  const seriesLeft_Contra = [
    { type: 'line', name: 'Contralateral leg', data: flattenData(rightThigh_LeftStrides), color: colorRight },
  ];
  const seriesLeft = [{ type: 'line', name: 'Left leg', data: flattenData(leftThigh), color: colorLeft }];
  const seriesLeftScissor = [
    { type: 'scatter', name: 'Scissors moment', data: flattenData(leftScissorsMoments), color: colorCS },
  ];
  const scissorsLeftGraphData = [...seriesLeft_Contra, ...seriesLeft, ...seriesLeftScissor];

  const leftThigh_RightStrides = useMemo(
    () => toGraphData(data?.stridesRight.leftThigh_RightStrides),
    [data?.stridesRight.leftThigh_RightStrides],
  );
  const rightThigh = useMemo(() => toGraphData(data?.stridesRight.rightThigh), [data?.stridesRight.rightThigh]);
  const rightScissorsMoments = useMemo(
    () => toScissorsData(data?.reportId.scissors_Right),
    [data?.reportId.scissors_Right],
  );

  const seriesRight_Contra = [
    { type: 'line', name: 'Contralateral leg', data: flattenData(leftThigh_RightStrides), color: colorLeft },
  ];
  const seriesRight = [{ type: 'line', name: 'Right leg', data: flattenData(rightThigh), color: colorRight }];
  const seriesRightScissor = [
    { type: 'scatter', name: 'Scissors moment', data: flattenData(rightScissorsMoments), color: colorCS },
  ];

  const scissorsRightGraphData = [...seriesRight_Contra, ...seriesRight, ...seriesRightScissor];

  //  Create Scissors Matrix graph Series
  const scissorsMatrix = data?.reportId.scissorsMatrix;
  const scissorsMatrixSeries = [{ data: [scissorsMatrix.col0[0], scissorsMatrix.col1[0]] }];

  // Define the data for the Joint Coupling graphs (x = Knee Angle, y = Hip Angle)
  const leftKneeAngles = data?.stridesLeft.leftKnee;
  const leftHipAngles = data?.stridesLeft.leftHip;

  const seriesJointCouplingData_Left = useMemo(
    () => toJointCouplingData(leftKneeAngles!, leftHipAngles!),
    [leftKneeAngles, leftHipAngles],
  );

  // Define the PointsOfInterest for the Joint Coupling Graphs
  // Defined as x: Hip.Left.Angles.Mean(midSwing_Left), y: Knee.Left.Angles.Mean(midSwing_Left)
  const heelOff_Left = data?.reportId.pointsOfInterests.col0[0];
  const toeOff_Left = data?.reportId.pointsOfInterests.col0[1];
  const midSwing_Left = data?.reportId.pointsOfInterests.col0[2];
  const terminalSwing_Left = data?.reportId.pointsOfInterests.col0[5];
  const initialContact_Left = data?.reportId.pointsOfInterests.col0[3];
  const midStance_Left = data?.reportId.pointsOfInterests.col0[4];
  const meanLeftKneeAngle = data?.reportId.kinematicData.col3;
  const meanLeftHipAngle = data?.reportId.kinematicData.col1;

  const pointsOfInterestJointCouplingData_Left = [
    {
      type: 'scatter',
      name: 'Points of Interest',
      data: [
        {
          x: meanLeftHipAngle[heelOff_Left],
          y: meanLeftKneeAngle[heelOff_Left],
        },
        {
          x: meanLeftHipAngle[toeOff_Left],
          y: meanLeftKneeAngle[toeOff_Left],
        },
        {
          x: meanLeftHipAngle[midSwing_Left],
          y: meanLeftKneeAngle[midSwing_Left],
        },
        {
          x: meanLeftHipAngle[terminalSwing_Left],
          y: meanLeftKneeAngle[terminalSwing_Left],
        },
        {
          x: meanLeftHipAngle[initialContact_Left],
          y: meanLeftKneeAngle[initialContact_Left],
        },
        {
          x: meanLeftHipAngle[midStance_Left],
          y: meanLeftKneeAngle[midStance_Left],
        },
      ],
    },
  ];

  const seriesJointCoupling_Left = [...seriesJointCouplingData_Left, ...pointsOfInterestJointCouplingData_Left];

  const rightKneeAngles = data?.stridesRight.rightKnee;
  const rightHipAngles = data?.stridesRight.rightHip;

  const seriesJointCouplingData_Right = useMemo(
    () => toJointCouplingData(rightKneeAngles!, rightHipAngles!),
    [rightKneeAngles, rightHipAngles],
  );

  // Define the PointsOfInterest for the Joint Coupling Graphs
  // Defined as x: Hip.Right.Angles.Mean(midSwing_Right), y: Knee.Right.Angles.Mean(midSwing_Right)
  const heelOff_Right = data?.reportId.pointsOfInterests.col1[0];
  const toeOff_Right = data?.reportId.pointsOfInterests.col1[1];
  const midSwing_Right = data?.reportId.pointsOfInterests.col1[2];
  const terminalSwing_Right = data?.reportId.pointsOfInterests.col1[5];
  const initialContact_Right = data?.reportId.pointsOfInterests.col1[3];
  const midStance_Right = data?.reportId.pointsOfInterests.col1[4];
  const meanRightKneeAngle = data?.reportId.kinematicData.col4;
  const meanRightHipAngle = data?.reportId.kinematicData.col2;

  const pointsOfInterestJointCouplingData_Right = [
    {
      type: 'scatter',
      name: 'Points of Interest',
      data: [
        {
          x: meanRightHipAngle[heelOff_Right],
          y: meanRightKneeAngle[heelOff_Right],
        },
        {
          x: meanRightHipAngle[toeOff_Right],
          y: meanRightKneeAngle[toeOff_Right],
        },
        {
          x: meanRightHipAngle[midSwing_Right],
          y: meanRightKneeAngle[midSwing_Right],
        },
        {
          x: meanRightHipAngle[terminalSwing_Right],
          y: meanRightKneeAngle[terminalSwing_Right],
        },
        {
          x: meanRightHipAngle[initialContact_Right],
          y: meanRightKneeAngle[initialContact_Right],
        },
        {
          x: meanRightHipAngle[midStance_Right],
          y: meanRightKneeAngle[midStance_Right],
        },
      ],
    },
  ];

  const seriesJointCoupling_Right = [...seriesJointCouplingData_Right, ...pointsOfInterestJointCouplingData_Right];

  // Get the data for the Variability Graphs
  const coordinativeVariabilityDataAll_Left = useMemo(
    () => toVariabilityData(data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Left_Stance.col0),
    [data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Left_Stance.col0],
  );

  const coordinativeVariabilityDataSelected_Left = useMemo(
    () => toVariabilityData(data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Left_Stance.col1),
    [data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Left_Stance.col1],
  );

  const coordinativeVariabilityDataMean_Left = useMemo(
    () => toVariabilityData(data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Left_Stance.col2),
    [data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Left_Stance.col2],
  );

  const coordinativeVariabilitySeriesAll_Left = [
    { type: 'area', name: 'All strides', data: coordinativeVariabilityDataAll_Left, color: variabilityAll_Left },
  ];
  const coordinativeVariabilitySeriesSelected_Left = [
    {
      type: 'line',
      name: 'Selected strides',
      data: coordinativeVariabilityDataSelected_Left,
      color: variabilitySelected_Left,
    },
  ];
  const coordinativeVariabilitySeriesMean_Left = [
    { type: 'area', name: 'Based on mean', data: coordinativeVariabilityDataMean_Left, color: variabilityMean_Left },
  ];

  const coordinativeVariabilitySeries_Left = [
    ...coordinativeVariabilitySeriesAll_Left,
    ...coordinativeVariabilitySeriesMean_Left,
  ];

  const ICAnnotation_Left = { title: 'IC', x: data?.reportId.pointsOfInterest_Stance.col0[0] };

  const MStAnnotation_Left = {
    title: 'MSt',
    x: data?.reportId.pointsOfInterest_Stance.col0[1],
  };

  const HOAnnotation_Left = {
    title: 'HO',
    x: data?.reportId.pointsOfInterest_Stance.col0[2],
  };

  const TOAnnotation_Left = {
    title: 'TO',
    x: coordinativeVariabilityDataAll_Left.length - 1,
  };

  const coordinativeVariabilityDataAll_Right = useMemo(
    () => toVariabilityData(data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Right_Stance.col0),
    [data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Right_Stance.col0],
  );

  const coordinativeVariabilityDataSelected_Right = useMemo(
    () => toVariabilityData(data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Right_Stance.col1),
    [data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Right_Stance.col1],
  );

  const coordinativeVariabilityDataMean_Right = useMemo(
    () => toVariabilityData(data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Right_Stance.col2),
    [data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Right_Stance.col2],
  );

  const coordinativeVariabilitySeriesAll_Right = [
    { type: 'area', name: 'All strides', data: coordinativeVariabilityDataAll_Right, color: variabilityAll_Right },
  ];
  const coordinativeVariabilitySeriesSelected_Right = [
    {
      type: 'line',
      name: 'Selected strides',
      data: coordinativeVariabilityDataSelected_Right,
      color: variabilitySelected_Right,
    },
  ];
  const coordinativeVariabilitySeriesMean_Right = [
    { type: 'area', name: 'Based on mean', data: coordinativeVariabilityDataMean_Right, color: variabilityMean_Right },
  ];

  const coordinativeVariabilitySeries_Right = [
    ...coordinativeVariabilitySeriesAll_Right,
    ...coordinativeVariabilitySeriesMean_Right,
  ];

  const ICAnnotation_Right = { title: 'IC', x: data?.reportId.pointsOfInterest_Stance.col1[0] };

  const MStAnnotation_Right = {
    title: 'MSt',
    x: data?.reportId.pointsOfInterest_Stance.col1[1],
  };

  const HOAnnotation_Right = {
    title: 'HO',
    x: data?.reportId.pointsOfInterest_Stance.col1[2],
  };

  const TOAnnotation_Right = {
    title: 'TO',
    x: coordinativeVariabilityDataAll_Right.length - 1,
  };

  const thighVelocityBarData = [
    {
      name: 'Left',
      data: [
        data?.reportId.thighVelocity.col0[0],
        data?.reportId.thighVelocity.col0[1],
        data?.reportId.thighVelocity.col0[2],
        data?.reportId.thighVelocity.col0[3],
      ],
      color: colorLeft,
    },
    {
      name: 'Right',
      data: [
        data?.reportId.thighVelocity.col1[0],
        data?.reportId.thighVelocity.col1[1],
        data?.reportId.thighVelocity.col1[2],
        data?.reportId.thighVelocity.col1[3],
      ],
      color: colorRight,
    },
  ];

  const thighAccelerationBarData = [
    {
      name: 'Left',
      data: [data?.reportId.thighAcceleration.col0[0], data?.reportId.thighAcceleration.col0[1]],
      color: colorLeft,
    },
    {
      name: 'Right',
      data: [data?.reportId.thighAcceleration.col1[0], data?.reportId.thighAcceleration.col1[1]],
      color: colorRight,
    },
  ];

  const thighAccelerationSeriesData_Left = useMemo(
    () => toLineGraphDataStrides(data?.reportId.thighAccelerationStance_Left),
    [data?.reportId.thighAccelerationStance_Left],
  );
  const thighAccelerationSeriesData_Right = useMemo(
    () => toLineGraphDataStrides(data?.reportId.thighAccelerationStance_Right),
    [data?.reportId.thighAccelerationStance_Right],
  );

  // #endregion

  // get the min and max for each col in the data
  const { yMinValue, yMaxValue } = useMemo(() => calculateYaxisRange(data), [data]);

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  if (!data) {
    return <IonText>Data not found.</IonText>;
  } else if (loading) {
    return <IonLoading isOpen={loading} message={'Drawing graphs, one moment please...'} />;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          {data && (
            <IonGrid fixed className='full_height'>
              <IonRow>
                <IonCol size='4' className=''>
                  <ScissorsGraphCard
                    series={scissorsLeftGraphData}
                    title={'Scissors Left'}
                    x={data?.reportId.pointsOfInterests.col0[3]}
                    x2={data?.reportId.pointsOfInterests.col0[4]}
                    colors={colorsScissorsLeft}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <ScissorsGraphCard
                    series={scissorsRightGraphData}
                    title={'Scissors Right'}
                    x={data?.reportId.pointsOfInterests.col1[3]}
                    x2={data?.reportId.pointsOfInterests.col1[4]}
                    colors={colorsScissorsRight}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <ScissorsColumnChart
                    title={'Average thigh velocity'}
                    subtitle={'during scissors'}
                    data={scissorsMatrixSeries}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='4' className=''>
                  <JointCouplingGraph data={seriesJointCoupling_Left} side={'Left'} />
                </IonCol>
                <IonCol size='4' className=''>
                  <JointCouplingGraph data={seriesJointCoupling_Right} side={'Right'} />
                </IonCol>
                <IonCol size='4' className=''>
                  <ThighBarGraph
                    data={thighVelocityBarData}
                    title={'Thigh Velocity'}
                    xAxisCategories={['Peak TSw', 'AV. TSw', 'Peak MSt', 'Av. Stance']}
                    stdData={data?.reportId.thighVelocity_Std}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='4' className=''>
                  <StanceCoordinativeVariabilityGraph
                    data={coordinativeVariabilitySeries_Left}
                    title={'Coordinative Variability Left'}
                    subtitle={'Pelvis / Thigh (s/s & c/t)'}
                    colors={colorsVariabilityLeft}
                    ICAnnotation={ICAnnotation_Left}
                    MStAnnotation={MStAnnotation_Left}
                    HOAnnotation={HOAnnotation_Left}
                    TOAnnotation={TOAnnotation_Left}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <StanceCoordinativeVariabilityGraph
                    data={coordinativeVariabilitySeries_Right}
                    title={'Coordinative Variability Right'}
                    subtitle={'Pelvis / Thigh (s/s & c/t)'}
                    ICAnnotation={ICAnnotation_Right}
                    MStAnnotation={MStAnnotation_Right}
                    HOAnnotation={HOAnnotation_Right}
                    TOAnnotation={TOAnnotation_Right}
                    colors={colorsVariabilityRight}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <ThighBarGraph
                    data={thighAccelerationBarData}
                    title={'Thigh Acceleration'}
                    xAxisCategories={['Peak TSw', 'Peak TSt']}
                    stdData={data?.reportId.thighAcceleration_Std}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='4' className=''>
                  <StanceLineGraph
                    series={thighAccelerationSeriesData_Left}
                    title={'Thigh Acceleration Left'}
                    ICAnnotation={ICAnnotation_Left}
                    MStAnnotation={MStAnnotation_Left}
                    HOAnnotation={HOAnnotation_Left}
                    TOAnnotation={TOAnnotation_Left}
                    yAxisMin={yMinValue}
                    yAxisMax={yMaxValue}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <StanceLineGraph
                    series={thighAccelerationSeriesData_Right}
                    title={'Thigh Acceleration Right'}
                    ICAnnotation={ICAnnotation_Right}
                    MStAnnotation={MStAnnotation_Right}
                    HOAnnotation={HOAnnotation_Right}
                    TOAnnotation={TOAnnotation_Right}
                    yAxisMin={yMinValue}
                    yAxisMax={yMaxValue}
                  />
                </IonCol>
                <IonCol size='4' className=''>
                  <DashboardCard>
                    <table className='ORYX_Table_Scroll'>
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>Left</th>
                          <th>Right</th>
                          <th>Symmetry</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='ORYX_Table_Head'>
                          <td colSpan={4}>Scissors</td>
                        </tr>
                        <tr>
                          <td>Average velocity</td>
                          <td>{scissorsMatrix.col0[0].toFixed(0) + ' m/s'}</td>
                          <td>{scissorsMatrix.col1[0].toFixed(0) + ' m/s'}</td>
                          <td>{calculateSymmetry(scissorsMatrix.col0[0], scissorsMatrix.col1[0], 0) + ' %'}</td>
                        </tr>
                        <tr className='ORYX_Table_Head'>
                          <td colSpan={4}>Average Velocities</td>
                        </tr>
                        <tr className=''>
                          <td>Terminal Swing</td>
                          <td>{data?.reportId.thighVelocity.col0[1].toFixed(0) + ' m/s'}</td>
                          <td>{data?.reportId.thighVelocity.col1[1].toFixed(0) + ' m/s'}</td>
                          <td>
                            {calculateSymmetry(
                              data?.reportId.thighVelocity.col0[1],
                              data?.reportId.thighVelocity.col1[1],
                              0,
                            ) + ' %'}
                          </td>
                        </tr>
                        <tr className=''>
                          <td>Stance</td>
                          <td>{data?.reportId.thighVelocity.col0[3].toFixed(0) + ' m/s'}</td>
                          <td>{data?.reportId.thighVelocity.col1[3].toFixed(0) + ' m/s'}</td>
                          <td>
                            {calculateSymmetry(
                              data?.reportId.thighVelocity.col0[3],
                              data?.reportId.thighVelocity.col1[3],
                              0,
                            ) + ' %'}
                          </td>
                        </tr>
                        <tr className='ORYX_Table_Head'>
                          <td colSpan={4} className=''>
                            Peak velocities and accelerations
                          </td>
                        </tr>
                        <tr className=''>
                          <td>Velocity Terminal Swing</td>
                          <td>{data?.reportId.thighVelocity.col0[0].toFixed(0) + ' m/s'}</td>
                          <td>{data?.reportId.thighVelocity.col1[0].toFixed(0) + ' m/s'}</td>
                          <td>
                            {calculateSymmetry(
                              data?.reportId.thighVelocity.col0[0],
                              data?.reportId.thighVelocity.col1[0],
                              0,
                            ) + ' %'}
                          </td>
                        </tr>
                        <tr className=''>
                          <td> Velocity Terminal Stance</td>
                          <td>{data?.reportId.thighVelocity.col0[2].toFixed(0) + ' m/s'}</td>
                          <td>{data?.reportId.thighVelocity.col1[2].toFixed(0) + ' m/s'}</td>
                          <td>
                            {calculateSymmetry(
                              data?.reportId.thighVelocity.col0[2],
                              data?.reportId.thighVelocity.col1[2],
                              0,
                            ) + ' %'}
                          </td>
                        </tr>
                        <tr className=''>
                          <td>Acceleration Terminal Swing</td>
                          <td>{data?.reportId.thighAcceleration.col0[0].toFixed(0)} m/s&sup2;</td>
                          <td>{data?.reportId.thighAcceleration.col1[0].toFixed(0)} m/s&sup2;</td>
                          <td>
                            {calculateSymmetry(
                              data?.reportId.thighAcceleration.col0[0],
                              data?.reportId.thighAcceleration.col1[0],
                              0,
                            ) + ' %'}
                          </td>
                        </tr>
                        <tr className=''>
                          <td>Acceleration Stance</td>
                          <td>{data?.reportId.thighAcceleration.col0[1].toFixed(0)} m/s&sup2;</td>
                          <td>{data?.reportId.thighAcceleration.col1[1].toFixed(0)} m/s&sup2;</td>
                          <td>
                            {calculateSymmetry(
                              data?.reportId.thighAcceleration.col0[1],
                              data?.reportId.thighAcceleration.col1[1],
                              0,
                            ) + ' %'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </DashboardCard>
                </IonCol>
              </IonRow>
              <IonRow>&nbsp;</IonRow>
            </IonGrid>
          )}
        </IonContent>
      </IonPage>
    );
  }
};
function calculateYaxisRange(data: RunReportData | undefined): { yMinValue: number; yMaxValue: number } {
  function minMax() {
    const minMax = [];
    const numCycles = Object.keys(data?.reportId.thighAccelerationStance_Left).length;
    for (let i = 0; i < numCycles; i++) {
      console.log('i: ', i);
      const min = Math.min(
        ...data?.reportId.thighAccelerationStance_Left[`col${i}`],
        ...data?.reportId.thighAccelerationStance_Right[`col${i}`],
      );
      const max = Math.max(
        ...data?.reportId.thighAccelerationStance_Left[`col${i}`],
        ...data?.reportId.thighAccelerationStance_Right[`col${i}`],
      );
      minMax.push({ min, max });
    }
    return minMax;
  }

  const minMaxArray = minMax();

  const minValueObject = minMaxArray.reduce(function (prev: any, curr: any) {
    return prev.min < curr.min ? prev : curr;
  });

  const maxValueObject = minMaxArray.reduce(function (prev: any, curr: any) {
    return prev.max > curr.max ? prev : curr;
  });

  const yMinValue = minValueObject.min - 1000;
  const yMaxValue = maxValueObject.max + 1000;
  return { yMinValue, yMaxValue };
}
