/** Walk analysis report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonItem,
  IonIcon,
  IonChip,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonModal,
  IonList,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import ankle from '../../../assets/avatars/walk/analysis/Ankle.png';
import hip from '../../../assets/avatars/walk/analysis/Hip.png';
import hipExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalStance.png';
import hipExtensionAtTerminalSwing from '../../../assets/avatars/walk/analysis/HipExtensionAtTerminalSwing.png';
import knee from '../../../assets/avatars/walk/analysis/Knee.png';
import kneeFlexionAtMidstance from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidstance.png';
import kneeFlexionAtMidswing from '../../../assets/avatars/walk/analysis/KneeFlexionAtMidswing.png';
import kneeExtensionAtTerminalStance from '../../../assets/avatars/walk/analysis/KneeExtensionAtTerminalStance.png';
import kneeStabilityAtLoadingResponse from '../../../assets/avatars/walk/analysis/KneeStabilityAtLoadingResponse.png';
import pelvicObliquity from '../../../assets/avatars/walk/analysis/PelvicObliquity.png';
import pelvis from '../../../assets/avatars/walk/analysis/Pelvis.png';
import varusValgus from '../../../assets/avatars/walk/analysis/VarusValgus.png';
import { transpose, toMatrix } from '../../../hooks/useReports';
import { Report, WalkReportData } from '../../../model';
import { NameField, StatusDotField } from '../components/Fields';
import { ReportSection } from '../components/ReportSection';
import './WalkAnalysisReportTab.css';
import {
  ModalHipExtensionTerminalStance,
  ModalKneeFlexionMidstance,
  ModalKneeFlexionMidswing,
  ModalKneeStabilityLoadingResponse,
  ModalPelvicObliquity,
  ModalVarusValgus,
  ModalMobilityStatus,
} from '../components/ModalsWalk';
import { closeCircleOutline, informationCircleOutline, personOutline, pricetagsOutline } from 'ionicons/icons';
import { ModalHipExtensionTerminalSwing } from '../components/ModalsWalk/ModalHipExtensionTerminalSwing';
import { ModalKneeExtensionTerminalStance } from '../components/ModalsWalk/ModalKneeExtensionTerminalStance';
import { useEffect, useMemo, useState } from 'react';
import { AttractorCard } from '../../ReportV2/components/Cards';
import { NameField_v2 } from '../../ReportV2/components/Fields';
import { DashboardCard } from '../../Report/components/dashboardCard';
import { ApexOptions } from 'apexcharts';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { colors } from '../components/CustomGraphComponents';
import Chart from 'react-apexcharts';
import { toJointCouplingData } from '../components/DataFunctions';
import { WalkJointCouplingGraph } from '../components/Graphs';

type MobilityProps = {
  title: string;
  img: string;
  romRow: number[];
  statusRow: number[];
};

/** Mobility report card content, showing avatar, left/right status and symmetry fields
 * @param img - The avatar
 * @param romRow - list of ROM data values
 * @param statusRow - list of status field tuples for (Left, Right)
 */
const Mobility = ({ title, img, romRow, statusRow }: MobilityProps) => {
  return (
    <IonGrid className=''>
      <IonRow>
        <IonCol className='ion-no-padding'>
          <IonImg src={img} className='reportAvatar' />
          <IonText className='mobilityReportCard_Title'>{title}</IonText>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <StatusDotField name='Left' value={romRow[0].toFixed(0) + '°'} status={statusRow[0]} />
        </IonCol>
        <IonCol>
          <StatusDotField name='Right' value={romRow[1].toFixed(0) + '°'} status={statusRow[1]} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

const kpiLabels = [
  ['Ok', 'Fair', 'Poor'],
  ['Hike', 'Ok', 'Drop'],
  ['Ok', 'Fair', 'Poor', 'No extension'],
];

type WalkReportProps = RouteComponentProps & {
  report: WalkReportData;
};

/** The Walk analysis report tab
 * @param report - The full walk report info and data
 */
export const WalkJointCouplingReportTab = ({ report }: WalkReportProps) => {
  const data = report;

  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('small');

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
    }
  }, [width]);

  //#region Data
  // Define the data for the Joint Coupling graphs (x = Knee Angle, y = Hip Angle)
  const leftKneeAngles = data?.stridesLeft.leftKnee;
  const leftHipAngles = data?.stridesLeft.leftHip;

  const seriesJointCouplingData_Left = useMemo(
    () => toJointCouplingData(leftKneeAngles!, leftHipAngles!),
    [leftKneeAngles, leftHipAngles],
  );

  // Define the PointsOfInterest for the Joint Coupling Graphs
  // Defined as x: Hip.Left.Angles.Mean(midSwing_Left), y: Knee.Left.Angles.Mean(midSwing_Left)
  const heelOff_Left = data?.reportId.pointsOfInterests.col0[0];
  const toeOff_Left = data?.reportId.pointsOfInterests.col0[1];
  const midSwing_Left = data?.reportId.pointsOfInterests.col0[2];
  const terminalSwing_Left = data?.reportId.pointsOfInterests.col0[5];
  const initialContact_Left = data?.reportId.pointsOfInterests.col0[3];
  const midStance_Left = data?.reportId.pointsOfInterests.col0[4];
  const meanLeftKneeAngle = data?.reportId.kinematicData.col6;
  const meanLeftHipAngle = data?.reportId.kinematicData.col2;

  const pointsOfInterestJointCouplingData_Left = [
    {
      type: 'scatter',
      name: 'Points of Interest',
      data: [
        {
          x: meanLeftHipAngle[heelOff_Left],
          y: meanLeftKneeAngle[heelOff_Left],
        },
        {
          x: meanLeftHipAngle[toeOff_Left],
          y: meanLeftKneeAngle[toeOff_Left],
        },
        {
          x: meanLeftHipAngle[midSwing_Left],
          y: meanLeftKneeAngle[midSwing_Left],
        },
        {
          x: meanLeftHipAngle[terminalSwing_Left],
          y: meanLeftKneeAngle[terminalSwing_Left],
        },
        {
          x: meanLeftHipAngle[initialContact_Left],
          y: meanLeftKneeAngle[initialContact_Left],
        },
        {
          x: meanLeftHipAngle[midStance_Left],
          y: meanLeftKneeAngle[midStance_Left],
        },
      ],
    },
  ];

  const seriesJointCoupling_Left = [...seriesJointCouplingData_Left, ...pointsOfInterestJointCouplingData_Left];

  const rightKneeAngles = data?.stridesRight.rightKnee;
  const rightHipAngles = data?.stridesRight.rightHip;

  const seriesJointCouplingData_Right = useMemo(
    () => toJointCouplingData(rightKneeAngles!, rightHipAngles!),
    [rightKneeAngles, rightHipAngles],
  );

  // Define the PointsOfInterest for the Joint Coupling Graphs
  // Defined as x: Hip.Right.Angles.Mean(midSwing_Right), y: Knee.Right.Angles.Mean(midSwing_Right)
  const heelOff_Right = data?.reportId.pointsOfInterests.col1[0];
  const toeOff_Right = data?.reportId.pointsOfInterests.col1[1];
  const midSwing_Right = data?.reportId.pointsOfInterests.col1[2];
  const terminalSwing_Right = data?.reportId.pointsOfInterests.col1[5];
  const initialContact_Right = data?.reportId.pointsOfInterests.col1[3];
  const midStance_Right = data?.reportId.pointsOfInterests.col1[4];
  const meanRightKneeAngle = data?.reportId.kinematicData.col7;
  const meanRightHipAngle = data?.reportId.kinematicData.col3;

  const pointsOfInterestJointCouplingData_Right = [
    {
      type: 'scatter',
      name: 'Points of Interest',
      data: [
        {
          x: meanRightHipAngle[heelOff_Right],
          y: meanRightKneeAngle[heelOff_Right],
        },
        {
          x: meanRightHipAngle[toeOff_Right],
          y: meanRightKneeAngle[toeOff_Right],
        },
        {
          x: meanRightHipAngle[midSwing_Right],
          y: meanRightKneeAngle[midSwing_Right],
        },
        {
          x: meanRightHipAngle[terminalSwing_Right],
          y: meanRightKneeAngle[terminalSwing_Right],
        },
        {
          x: meanRightHipAngle[initialContact_Right],
          y: meanRightKneeAngle[initialContact_Right],
        },
        {
          x: meanRightHipAngle[midStance_Right],
          y: meanRightKneeAngle[midStance_Right],
        },
      ],
    },
  ];

  const seriesJointCoupling_Right = [...seriesJointCouplingData_Right, ...pointsOfInterestJointCouplingData_Right];

  //#endregion

  if (!data) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed className='full_height'>
            <IonRow className='ion-no-padding full_height'>
              <IonCard className='ORYX_GraphCard'>
                <IonCardHeader className='attractorCard_Header' style={{ height: '35px' }}>
                  <IonGrid fixed className='ion-no-padding '>
                    <IonRow className='ion-no-padding ion-align-items-center' style={{ paddingTop: '5px' }}>
                      <IonCol size='9' className=''>
                        <IonCardTitle className='attractorCard_Title'>Hip / Knee Coupling </IonCardTitle>
                      </IonCol>

                      <IonCol size='3' className='ion-justify-content-center'>
                        {/* <IonButtons className='ion-justify-content-center'>
                            <IonButton
                              slot='icon-only'
                              fill='clear'
                              className='attractorCard_Header_Buttons'
                              onClick={() => setShowModal(true)}>
                              <IonIcon icon={informationCircleOutline} />
                            </IonButton>
                          </IonButtons> */}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCardHeader>
                <IonCardContent
                  className=''
                  style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', height: '92.5%' }}>
                  <IonRow className='ion-justify-content-center' style={{ height: '50%' }}>
                    <IonCol size='10' className=''>
                      <WalkJointCouplingGraph data={seriesJointCoupling_Left} side={'Left'} />
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-justify-content-center' style={{ height: '50%' }}>
                    <IonCol size='10' className=''>
                      <WalkJointCouplingGraph data={seriesJointCoupling_Right} side={'Right'} />
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
