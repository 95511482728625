import React from 'react';
import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonSlides,
  IonSlide,
  IonIcon,
} from '@ionic/react';
import './modalindex.css';
import IBimagekneeextensionpreswing1 from './assets/IBimagekneeextensionpreswing1.png';
import IBimagekneeextensionpreswing2 from './assets/IBimagekneeextensionpreswing2.png';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  direction: 'vertical',
};

export const ModalKneeExtensionPreswing: React.FC = () => {
  return (
    <>
      <IonCardHeader className='reportInfoModal_Header'>
        <div>
          <IonCardTitle className='reportInfoModal_Title'>Knee extension</IonCardTitle>
          <IonCardSubtitle className='reportInfoModal_Subtitle'>Preswing</IonCardSubtitle>
        </div>
      </IonCardHeader>

      <IonSlides pager={true} options={slideOpts} mode='ios'>
        <IonSlide>
          <IonGrid>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='8' size-xl='8'>
                <div>
                  <IonImg src={IBimagekneeextensionpreswing1} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  From landing to midstance, the leg is compressed (also see: midstance).
                  <br></br>
                  <br></br>
                  If the leg is sufficiently stiff, it can act as a spring mass mechanism. At compression, kinetic
                  energy is stored by stretched tendons and fascia for re-use in terminal stance.
                  <br></br>
                  <br></br>
                  Just like a spring mass, the end position of the leg should be similar to the initial knee position,
                  resulting in similar drop and jump height.
                  <br></br>
                  <br></br>
                  As a key hub for energy transport, the knee plays an important role in this mechanism.
                </div>
              </IonCol>
            </IonRow>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronDownOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>

        <IonSlide>
          <IonGrid>
            <IonRow class='ion-hide-xl-down'>
              <IonCol class='ion-float-right'>
                <IonIcon icon={chevronUpOutline} className='reportInfoModal_Icon' />
              </IonCol>
            </IonRow>
            <IonRow class='ion-align-items-center'>
              <IonCol size='12' size-lg='8' size-xl='8'>
                <div>
                  <IonImg src={IBimagekneeextensionpreswing2} class='reportInfoModal_Avatar' />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  After reversing the falling movement (at midstance), this elastic stretch is released with leg
                  extension as a result, catapulting the body back upwards.
                  <br></br>
                  <br></br>
                  After unloading elastic energy, there is very little time and range of motion (the hip is almost fully
                  extended) to add energy to the push-off.
                  <br></br>
                  <br></br>
                  Although there must be knee extension for proximo distal sequence, excessive extension indicates
                  emphasis of push-off, resulting in an excessive forward pelvic rotation.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonSlide>
      </IonSlides>
    </>
  );
};
