// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAThVaDOt2v_EUU_qB8HbX9wFOo2O0VsmQ',
  authDomain: 'test-oryxapp.firebaseapp.com',
  projectId: 'test-oryxapp',
  databaseURL: 'https://test-oryxapp.europe-west1.firebasedatabase.app',
  storageBucket: 'test-oryxapp.appspot.com',
  messagingSenderId: '191503776578',
  appId: '1:191503776578:web:f8c079ed27bcafe3e826db',
  measurementId: 'G-PY20FS6253',
};

// Ensure that you do not initialize twice.
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Initialize Analytics and get a reference to the service
export const analytics = firebase.analytics();
export const myFirebase = firebase;
