/** The Run Kinematics report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonCard,
  IonLoading,
  IonToggle,
  IonButton,
  IonButtons,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonCardContent,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { Report, RunReportData, StridesData, WalkReportData } from '../../../model';
import { toGraphData, flattenData, toVariabilityData } from '../components/DataFunctions';
import { colorLeft, colorRight } from '../components/CustomGraphComponents';

import '../components/Graphs/graphCard.css';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { KinematicsGraph, VariabilityOverviewGraph } from '../components/Graphs';
import { useEffect, useMemo, useState } from 'react';
import { toMatrix, transpose } from '../../../hooks/useReports';
import { informationCircleOutline } from 'ionicons/icons';
import { title } from 'process';

type KinematicsReportProps = RouteComponentProps & {
  report: WalkReportData;
  setLoadingData: (loading: boolean) => void;
};

function getLeftRightSeriesData(leftData: StridesData | undefined, rightData: StridesData | undefined) {
  const left = toGraphData(leftData);
  const seriesLeft = [{ type: 'line', name: 'Left', data: flattenData(left), color: colorLeft }];
  const right = toGraphData(rightData);
  const seriesRight = [{ type: 'line', name: 'Right', data: flattenData(right), color: colorRight }];
  const series = [...seriesLeft, ...seriesRight];
  return series;
}

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const WalkKinematicsReportTab = ({ report, setLoadingData }: KinematicsReportProps) => {
  setLoadingData(true);
  const data = report as WalkReportData | undefined;
  const romData: number[][] = transpose(data?.reportId.rom);
  const tableData: number[][] = toMatrix(data?.reportId.spatioTemporal);
  const statusData = transpose(data?.reportId.mobilityStatus);
  const classNames = ['backgroundRed', 'backgroundOrange', 'backgroundGreen'];
  const color = (status: number) => {
    return classNames[status];
  };

  const getHighSymmetryScore = (symmetry: number) => {
    if (symmetry <= 25) return 2;
    else if (symmetry > 25 && symmetry <= 40) return 1;
    else return 0;
  };

  const getSymmetryScore = (symmetry: number) => {
    if (symmetry <= 10) return 2;
    else if (symmetry > 10 && symmetry <= 20) return 1;
    else return 0;
  };

  const getHipExtensionScore = (hipExtension: number) => {
    if (hipExtension <= -8) return 2;
    else if (hipExtension > -8 && hipExtension < -5) return 1;
    else return 0;
  };

  const getStanceFlexionScore = (stanceFlexion: number) => {
    if (stanceFlexion >= 12 && stanceFlexion <= 25) return 2;
    else if (stanceFlexion >= 8 && stanceFlexion < 12) return 1;
    else if (stanceFlexion > 25 && stanceFlexion <= 30) return 1;
    else return 0;
  };

  const getVarusValgusScore = (varusValgus: number) => {
    if (varusValgus >= -3 && varusValgus < 3) return 2;
    else if (varusValgus >= -6 && varusValgus < -3) return 1;
    else if (varusValgus > 3 && varusValgus <= 6) return 1;
    else return 0;
  };

  const annotations_Left = data?.reportId.pointsOfInterests.col0;
  const annotations_Right = data?.reportId.pointsOfInterests.col1;

  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);
  const [seriesPelvis_Sagittal, setSeriesPelvis_Sagittal] = useState<ApexOptions['series']>([]);
  const [seriesPelvis_Coronal, setSeriesPelvis_Coronal] = useState<ApexOptions['series']>([]);
  const [seriesPelvis_Transversal, setSeriesPelvis_Transversal] = useState<ApexOptions['series']>([]);
  const [seriesHip_Sagittal, setSeriesHip_Sagittal] = useState<ApexOptions['series']>([]);
  const [seriesHip_Coronal, setSeriesHip_Coronal] = useState<ApexOptions['series']>([]);
  const [seriesHip_Transversal, setSeriesHip_Transversal] = useState<ApexOptions['series']>([]);
  const [seriesKnee_Sagittal, setSeriesKnee_Sagittal] = useState<ApexOptions['series']>([]);
  const [seriesKnee_Coronal, setSeriesKnee_Coronal] = useState<ApexOptions['series']>([]);
  const [seriesKnee_Transversal, setSeriesKnee_Transversal] = useState<ApexOptions['series']>([]);
  const [seriesAnkle_Sagittal, setSeriesAnkle_Sagittal] = useState<ApexOptions['series']>([]);
  const [seriesAnkle_Coronal, setSeriesAnkle_Coronal] = useState<ApexOptions['series']>([]);
  const [seriesAnkle_Transversal, setSeriesAnkle_Transversal] = useState<ApexOptions['series']>([]);

  const toggleShowAllStrides = () => {
    setShowAllStrides(!showAllStrides);
    localStorage.setItem('showAllStridesKinematics', !showAllStrides ? 'true' : 'false');
  };

  useEffect(() => {
    const allStridesLocalStorage_Item = localStorage.getItem('showAllStridesKinematics');
    if (allStridesLocalStorage_Item === 'true') {
      setShowAllStrides(true);
      setSeriesPelvis_Sagittal(getLeftRightSeriesData(data?.stridesLeft.leftPelvis, data?.stridesRight.rightPelvis));
      setSeriesPelvis_Coronal(
        getLeftRightSeriesData(
          data?.stridesCoronalLeft.leftPelvis_Coronal,
          data?.stridesCoronalRight.rightPelvis_Coronal,
        ),
      );
      setSeriesPelvis_Transversal(
        getLeftRightSeriesData(
          data?.stridesTransversalLeft.leftPelvis_Transversal,
          data?.stridesTransversalRight.rightPelvis_Transversal,
        ),
      );

      setSeriesHip_Sagittal(getLeftRightSeriesData(data?.stridesLeft.leftHip, data?.stridesRight.rightHip));
      setSeriesHip_Coronal(
        getLeftRightSeriesData(data?.stridesCoronalLeft.leftHip_Coronal, data?.stridesCoronalRight.rightHip_Coronal),
      );
      setSeriesHip_Transversal(
        getLeftRightSeriesData(
          data?.stridesTransversalLeft.leftHip_Transversal,
          data?.stridesTransversalRight.rightHip_Transversal,
        ),
      );

      setSeriesKnee_Sagittal(getLeftRightSeriesData(data?.stridesLeft.leftKnee, data?.stridesRight.rightKnee));
      setSeriesKnee_Coronal(
        getLeftRightSeriesData(data?.stridesCoronalLeft.leftKnee_Coronal, data?.stridesCoronalRight.rightKnee_Coronal),
      );
      setSeriesKnee_Transversal(
        getLeftRightSeriesData(
          data?.stridesTransversalLeft.leftKnee_Transversal,
          data?.stridesTransversalRight.rightKnee_Transversal,
        ),
      );

      setSeriesAnkle_Sagittal(getLeftRightSeriesData(data?.stridesLeft.leftAnkle, data?.stridesRight.rightAnkle));
      setSeriesAnkle_Coronal(
        getLeftRightSeriesData(
          data?.stridesCoronalLeft.leftAnkle_Coronal,
          data?.stridesCoronalRight.rightAnkle_Coronal,
        ),
      );
      setSeriesAnkle_Transversal(
        getLeftRightSeriesData(
          data?.stridesTransversalLeft.leftAnkle_Transversal,
          data?.stridesTransversalRight.rightAnkle_Transversal,
        ),
      );
    } else {
      localStorage.setItem('showAllStridesKinematics', 'false');
      setShowAllStrides(false);
      setSeriesPelvis_Sagittal([
        { type: 'line', name: 'Left', data: toVariabilityData(data?.reportId.kinematicData.col0), color: colorLeft },
        { type: 'line', name: 'Right', data: toVariabilityData(data?.reportId.kinematicData.col1), color: colorRight },
      ]);
      setSeriesPelvis_Coronal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col0),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col1),
          color: colorRight,
        },
      ]);
      setSeriesPelvis_Transversal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col0),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col1),
          color: colorRight,
        },
      ]);

      setSeriesHip_Sagittal([
        { type: 'line', name: 'Left', data: toVariabilityData(data?.reportId.kinematicData.col2), color: colorLeft },
        { type: 'line', name: 'Right', data: toVariabilityData(data?.reportId.kinematicData.col3), color: colorRight },
      ]);
      setSeriesHip_Coronal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col2),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col3),
          color: colorRight,
        },
      ]);
      setSeriesHip_Transversal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col2),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col3),
          color: colorRight,
        },
      ]);

      setSeriesKnee_Sagittal([
        { type: 'line', name: 'Left', data: toVariabilityData(data?.reportId.kinematicData.col6), color: colorLeft },
        { type: 'line', name: 'Right', data: toVariabilityData(data?.reportId.kinematicData.col7), color: colorRight },
      ]);
      setSeriesKnee_Coronal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col6),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col7),
          color: colorRight,
        },
      ]);
      setSeriesKnee_Transversal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col6),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col7),
          color: colorRight,
        },
      ]);

      setSeriesAnkle_Sagittal([
        { type: 'line', name: 'Left', data: toVariabilityData(data?.reportId.kinematicData.col10), color: colorLeft },
        { type: 'line', name: 'Right', data: toVariabilityData(data?.reportId.kinematicData.col11), color: colorRight },
      ]);
      setSeriesAnkle_Coronal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col10),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col11),
          color: colorRight,
        },
      ]);
      setSeriesAnkle_Transversal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col10),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col11),
          color: colorRight,
        },
      ]);
    }
  }, [data, showAllStrides]);

  if (!data) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed>
            {/* Pelvis */}
            <IonRow className=''>
              <IonCol size='12' className=''>
                <IonCard className=''>
                  <IonCardHeader className='attractorCard_Header'>
                    <IonGrid className=''>
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='9' className=''>
                          <IonText className='ORYX_GraphTitle'>Pelvis</IonText>
                        </IonCol>

                        <IonCol size='3' className='ion-justify-content-center'>
                          <IonRow className='ion-no-padding ion-justify-content-center'>
                            <IonText className='attractorCard_Title'>Show all Strides</IonText>
                          </IonRow>
                          <IonRow className='ion-no-padding ion-justify-content-center'>
                            <IonToggle
                              mode='ios'
                              className='icon-custom'
                              color='primary'
                              checked={showAllStrides}
                              onIonChange={(e) => {
                                toggleShowAllStrides();
                              }}></IonToggle>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesPelvis_Sagittal}
                          title={'Sagittal'}
                          subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesPelvis_Coronal}
                          title={'Frontal'}
                          subtitle={'Drop (-) / Hike (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesPelvis_Transversal}
                          title={'Transversal'}
                          subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Hip */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className=''>
                  <IonCardHeader className='attractorCard_Header'>
                    <IonGrid className=''>
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='9' className=''>
                          <IonText className='ORYX_GraphTitle'>Hip</IonText>
                        </IonCol>

                        <IonCol size='3' className='ion-justify-content-center'></IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesHip_Sagittal}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesHip_Coronal}
                          title={'Frontal'}
                          subtitle={'Abduction (-) / Adduction (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesHip_Transversal}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Knee */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className=''>
                  <IonCardHeader className='attractorCard_Header'>
                    <IonGrid className=''>
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='9' className=''>
                          <IonText className='ORYX_GraphTitle'>Knee</IonText>
                        </IonCol>

                        <IonCol size='3' className='ion-justify-content-center'></IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesKnee_Sagittal}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesKnee_Coronal}
                          title={'Frontal'}
                          subtitle={'Valgus (-) / Varus (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesKnee_Transversal}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Ankle */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className=''>
                  <IonCardHeader className='attractorCard_Header'>
                    <IonGrid className=''>
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='9' className=''>
                          <IonText className='ORYX_GraphTitle'>Ankle</IonText>
                        </IonCol>

                        <IonCol size='3' className='ion-justify-content-center'></IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesAnkle_Sagittal}
                          title={'Sagittal'}
                          subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesAnkle_Coronal}
                          title={'Frontal'}
                          subtitle={'Eversion (-) / Inversion (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesAnkle_Transversal}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonRow class='ion-no-padding'>
              <IonCol size='6'>
                <IonCard className=''>
                  <IonCardHeader className='attractorCard_Header'>
                    <IonGrid className=''>
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='9' className=''>
                          <IonText className='ORYX_GraphTitle'>Range of Motion</IonText>
                        </IonCol>
                        <IonCol size='3' className='ion-justify-content-center'></IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow className='ion-padding-vertical full_height ion-align-items-start'>
                      <IonCol size='12' className='ion-no-padding'>
                        <table className='ORYX_Table_noMargin'>
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>Left</th>
                              <th>Right</th>
                              <th>Symmetry</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='ORYX_Table_Head'>
                              <td> Pelvis</td>
                              <td className={color(statusData[0][0])}>{romData[0][0].toFixed(1) + '°'}</td>
                              <td className={color(statusData[0][1])}>{romData[0][1].toFixed(1) + '°'}</td>
                              <td className={color(getHighSymmetryScore(romData[0][2]))}>
                                {romData[0][2].toFixed(0) + '%'}
                              </td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td> Hip</td>
                              <td className={color(statusData[1][0])}>{romData[1][0].toFixed(1) + '°'}</td>
                              <td className={color(statusData[1][1])}>{romData[1][1].toFixed(1) + '°'}</td>
                              <td className={color(getSymmetryScore(romData[1][2]))}>
                                {romData[1][2].toFixed(0) + '%'}
                              </td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Flexion</td>
                              <td>{romData[2][0].toFixed(1) + '°'}</td>
                              <td>{romData[2][1].toFixed(1) + '°'}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Extension</td>
                              <td className={color(getHipExtensionScore(romData[3][0]))}>
                                {romData[3][0].toFixed(1) + '°'}
                              </td>
                              <td className={color(getHipExtensionScore(romData[3][1]))}>
                                {romData[3][1].toFixed(1) + '°'}
                              </td>
                              <td></td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td>Knee</td>
                              <td className={color(statusData[2][0])}>{romData[4][0].toFixed(1) + '°'}</td>
                              <td className={color(statusData[2][1])}>{romData[4][1].toFixed(1) + '°'}</td>
                              <td className={color(getSymmetryScore(romData[4][2]))}>
                                {romData[4][2].toFixed(0) + '%'}
                              </td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'> Stance flexion</td>
                              <td className={color(getStanceFlexionScore(romData[5][0]))}>
                                {romData[5][0].toFixed(1) + '°'}
                              </td>
                              <td className={color(getStanceFlexionScore(romData[5][1]))}>
                                {romData[5][1].toFixed(1) + '°'}
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Varus Valgus</td>
                              <td className={color(getVarusValgusScore(romData[6][0]))}>
                                {romData[6][0].toFixed(1) + '°'}
                              </td>
                              <td className={color(getVarusValgusScore(romData[6][1]))}>
                                {romData[6][1].toFixed(1) + '°'}
                              </td>
                              <td></td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td>Ankle</td>
                              <td className={color(statusData[3][0])}>{romData[7][0].toFixed(1) + '°'}</td>
                              <td className={color(statusData[3][1])}>{romData[7][1].toFixed(1) + '°'}</td>
                              <td className={color(getSymmetryScore(romData[7][2]))}>
                                {romData[7][2].toFixed(0) + '%'}
                              </td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Dorsal flexion</td>
                              <td>{romData[8][0].toFixed(1) + '°'}</td>
                              <td>{romData[8][1].toFixed(1) + '°'}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Plantar flexion</td>
                              <td>{romData[9][0].toFixed(1) + '°'}</td>
                              <td>{romData[9][1].toFixed(1) + '°'}</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol size='6'>
                <IonCard className='' style={{ height: '95%' }}>
                  <IonCardHeader className='attractorCard_Header'>
                    <IonGrid className=''>
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='9' className=''>
                          <IonText className='ORYX_GraphTitle'>Spatiotemporal information</IonText>
                        </IonCol>

                        <IonCol size='3' className='ion-justify-content-center'></IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow className='ion-padding-vertical full_height ion-align-items-start'>
                      <IonCol size='12' className='ion-no-padding'>
                        <table className='ORYX_Table_noMargin'>
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>Left</th>
                              <th>Right</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='ORYX_Table_Head'>
                              <td>Stance phase (Percentage of stride time)</td>
                              <td>{tableData[3][1].toFixed(0) + ' %'}</td>
                              <td>{tableData[4][1].toFixed(0) + ' %'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Stance phase (seconds)</td>
                              <td>{tableData[0][1].toFixed(2) + ' s'}</td>
                              <td>{tableData[1][1].toFixed(2) + ' s'}</td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td>Swing phase (Percentage of stride time)</td>
                              <td>{tableData[3][2].toFixed(0) + ' %'}</td>
                              <td>{tableData[4][2].toFixed(0) + ' %'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Swing phase (seconds)</td>
                              <td>{tableData[0][2].toFixed(2) + ' s'}</td>
                              <td>{tableData[1][2].toFixed(2) + ' s'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
          {/* )} */}
        </IonContent>
      </IonPage>
    );
  }
};
