import {
  IonModal,
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonButtons,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from '@ionic/react';
import { closeCircleOutline, informationCircleOutline } from 'ionicons/icons';
import { useState } from 'react';

import './cards.css';

type DashboardCardProps = {
  title?: string;
  subtitle?: string;
  info?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
};

/** Card in the analysis page to show the avatar and attractor field
 * @param img - The avatar image
 * @param labels - Attractor labels for the status field, tuple of (Left, Right) values
 */
export const DashboardCard = ({ title, subtitle, info, children, className }: DashboardCardProps) => {
  const [showModal, setShowModal] = useState(false);
  const showHeader = title || subtitle || info;

  return (
    <>
      <IonModal isOpen={showModal} cssClass='ORYX_Modal_Large' onDidDismiss={() => setShowModal(false)}>
        {info}
        <IonButton slot='icon-only' onClick={() => setShowModal(false)} fill='clear' className='ORYX_ModalButton_Close'>
          <IonIcon icon={closeCircleOutline} />
        </IonButton>
      </IonModal>

      <IonCard className='ORYX_DashboardCard'>
        {showHeader && (
          <IonCardHeader className='attractorCard_Header'>
            <IonGrid fixed className='ion-no-padding'>
              <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row'>
                <IonCol size='11' className='ion-padding-start'>
                  <IonCardTitle className='attractorCard_Title'>{title}</IonCardTitle>
                  {subtitle && <IonCardSubtitle className='attractorCard_Subtitle'>{subtitle}</IonCardSubtitle>}
                </IonCol>
                {info && (
                  <IonCol size='1' className='ion-justify-content-center'>
                    <IonButtons className='ion-justify-content-center'>
                      <IonButton
                        slot='icon-only'
                        fill='clear'
                        className='attractorCard_Header_Buttons'
                        onClick={() => setShowModal(true)}>
                        <IonIcon icon={informationCircleOutline} />
                      </IonButton>
                    </IonButtons>
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
          </IonCardHeader>
        )}

        <IonCardContent className='full_height ion-no-padding'>
          <IonGrid class='' fixed>
            {children}
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};
