/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Login form Component, including the logo.
 */

import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonText,
  IonToast,
} from '@ionic/react';
import { lockClosedOutline, mailOutline, eyeOffOutline, eyeOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { analytics } from '../../hooks/firebaseConfig';

import logo from '../../assets/oryx-logo.png';
import { useAuthContext } from '../authContext';
import './loginForm.css';

export const LoginForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showLoginError, setShowLoginError] = useState<boolean>(false);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setShowPassword(!showPassword);
  };

  const { signInWithEmailAndPassword, state, clearError } = useAuthContext();

  useEffect(() => {
    if (state.error) {
      console.log(state.error);
      setShowLoginError(true);
    }
  }, [state]);

  function login(e: React.FormEvent) {
    analytics.logEvent('login');
    e.preventDefault();
    setFormSubmitted(true);
    if (!!email && !!password) {
      signInWithEmailAndPassword(email, password);
    }
  }

  const handleEmail = (e: any) => {
    setEmail(e.detail.value);
  };

  const handlePassword = (e: any) => {
    setPassword(e.detail.value);
  };

  return (
    <>
      <IonToast
        isOpen={showLoginError}
        message='Incorrect username / password. Please try again.'
        position='bottom'
        color='danger'
        onDidDismiss={() => {
          setShowLoginError(false);
          clearError();
        }}
        duration={2500}
      />
      <form noValidate onSubmit={login}>
        <IonGrid fixed className='login_form'>
          <IonRow className='test'>
            <IonCol>
              <IonText>Test Environment!</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size='12' className='ion-padding'>
              <IonImg src={logo} className='login_logo'></IonImg>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size='12' className='ion-padding'>
              <div>Welcome back! Please sign in to your account.</div>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size='12' className='ion-padding'>
              <IonList>
                <IonItem lines='full' className='ion-padding-top'>
                  <IonIcon icon={mailOutline} slot='start'></IonIcon>
                  <IonInput name='email' type='email' placeholder='Email' onIonChange={handleEmail}></IonInput>
                </IonItem>
                <IonItem lines='full' className='ion-padding-top'>
                  {showPassword ? (
                    <IonIcon icon={eyeOffOutline} slot='end' onClick={togglePassword} id='eyeOff'></IonIcon>
                  ) : (
                    <IonIcon icon={eyeOutline} slot='end' onClick={togglePassword} id='eyeOn'></IonIcon>
                  )}
                  <IonIcon icon={lockClosedOutline} slot='start'></IonIcon>
                  <IonInput
                    name='password'
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Password'
                    onIonChange={handlePassword}></IonInput>
                </IonItem>

                {formSubmitted && (!email || !password) && (
                  <IonText color='danger'>
                    <p className='ion-padding-start'>Email and password are required.</p>
                  </IonText>
                )}
              </IonList>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size='6' className='ion-padding'></IonCol>
            <IonCol size='6' className='ion-align-self-end'>
              <IonItem lines='none'>
                <IonLabel
                  onClick={() => {
                    history.push(`/resetPassword`);
                  }}>
                  <div className='resetPassword_Button'>Forgot password?</div>
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size='6' className='ion-padding'>
              <IonButton type='submit' expand='block' className='primary'>
                Sign in
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
    </>
  );
};
