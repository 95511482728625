import * as React from 'react';
import Chart from 'react-apexcharts';

import {
  ResponsiveContainer,
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Scatter,
  LabelList,
  ReferenceLine,
  ErrorBar,
} from 'recharts';

import { Asterisk, colors, NoDot } from '../CustomGraphComponents';
import { ApexOptions } from 'apexcharts';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import { useEffect, useState } from 'react';

type ProjectionFootPlantGraphProps = {
  data: any;
  subtitle: string;
};

export const ProjectionFootPlantGraph = ({ data, subtitle }: ProjectionFootPlantGraphProps) => {
  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('small');

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
      } else {
        setWindowSize('small');
      }
      console.log('width', height);
    }
  }, [width]);

  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      offsetX: -10,
    },
    xaxis: {
      min: -0.4,
      max: 0.4,
      type: 'numeric',
      labels: {
        style: {
          fontSize: '8px',
        },
        offsetY: -5,
      },
    },
    yaxis: {
      min: 0,
      max: 0.2,
      tickAmount: 2,
      labels: {
        formatter: function (value: number) {
          return value.toFixed(1);
        },
        style: {
          fontSize: '8px',
        },
      },
    },
    subtitle: {
      text: subtitle,
      align: 'center',
      floating: true,
      offsetY: 5,
      style: {
        fontSize: '8px',
        fontFamily: 'Montserrat',
      },
    },

    stroke: {
      width: [1],
      dashArray: [0],
    },
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },

    markers: {
      size: [4],
      strokeWidth: 1,
      showNullDataPoints: false,
      colors: colors,
    },
    annotations: {
      xaxis: [
        {
          x: 0,
          borderColor: '#000',
          label: {
            text: 'COM',
            position: 'top',
            orientation: 'horizontal',
            style: {
              fontSize: '8px',
              fontFamily: 'Montserrat',
              color: '#000',
            },
          },
        },
      ],
    },
    tooltip: {
      enabled: false,
    },
  };
  return (
    <>
      <div className='' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <Chart options={options} series={data} type='scatter' height={windowSize === 'small' ? 100 : 125} />
      </div>
    </>
  );
};
