/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * ORYX Test page.
 */

import { useEffect, useState } from 'react';
import {
  IonPage,
  IonContent,
  IonGrid,
  IonTitle,
  IonCol,
  IonRow,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonLabel,
  IonImg,
  IonSpinner,
  IonLoading,
  IonButton,
  IonButtons,
  IonListHeader,
  IonAvatar,
} from '@ionic/react';
import { ORYXHeader } from '../../../components/menuItems/Header';
import './ORYX.css';
import Wifi from '../../../assets/icons/wifi.svg';
import { useHardwareContext } from '../../../components/hardwareContext';
import { GatewaysCollection, HardwareInstance } from '../../../model';
import { informationCircleOutline } from 'ionicons/icons';
import gatewayIcon from '../../../assets/icons/gatewayIcon.png';
import { useHistory } from 'react-router';

interface RouterLinkProps {
  hardwareInfo: RouterInfoObject;
}

const RouterCard = ({ hardwareInfo }: RouterLinkProps) => {
  console.log('hardwareInfo', hardwareInfo);
  const history = useHistory();
  return (
    <IonCard
      className='network_Card animate'
      // routerLink={`/measurements/ORYX/${hardwareInfo.hardwareId}`}
      // routerDirection='forward'
      onClick={() =>
        history.push(`/measurements/ORYX/${hardwareInfo.hardwareId}`, { from: '/measurements/ORYX/selectNetwork' })
      }>
      {/* <IonCardHeader>
        <IonImg src={Wifi} alt='wifi' className='network_Icon' />
        <IonCardTitle className='network_Title'>{hardwareInfo.name}</IonCardTitle>
      </IonCardHeader> */}

      <IonCardHeader className='attractorCard_Header' mode='md'>
        <IonGrid fixed className='ion-no-padding'>
          <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row'>
            <IonCol size='10' className='' style={{ paddingLeft: '10px' }}>
              <IonCardTitle className='attractorCard_Title'>{hardwareInfo.name}</IonCardTitle>
            </IonCol>
            <IonCol size='2' className='ion-justify-content-center'>
              <IonButtons className='ion-justify-content-center'>
                <IonButton slot='icon-only' fill='clear' className='attractorCard_Header_Buttons'>
                  <IonIcon icon={Wifi} />
                </IonButton>
              </IonButtons>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardHeader>
      <IonCardContent className='reportcardContent'>
        <IonCardTitle className='attractorCard_Title' style={{ marginBottom: '10px' }}>
          Registered gateways:
        </IonCardTitle>
        {/* <IonListHeader>Registered gateways:</IonListHeader> */}

        {Object.entries(hardwareInfo.gateways).map(([key, value]) => {
          return (
            <IonItem key={key} lines='none'>
              <IonLabel>{value.hostName}</IonLabel>
              <IonAvatar slot='start'>
                <IonImg src={gatewayIcon} alt='gateway' />
              </IonAvatar>
            </IonItem>
          );
        })}
        {/* <IonItem>
          <IonLabel>Gateway 1</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Gateway 2</IonLabel>
        </IonItem> */}
      </IonCardContent>
    </IonCard>
  );
};

type RouterInfoObject = {
  name: string;
  hardwareId: string;
  gateways: GatewaysCollection;
};

export const SelectNetworkPage = () => {
  const [availableNetworks, setAvailableNetworks] = useState<RouterInfoObject[]>([]);
  const hardwareContext = useHardwareContext();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    console.log('hardwareData: ', hardwareContext.hardwareData);
    Object.entries(hardwareContext.hardwareData).forEach(([key, value]) => {
      setAvailableNetworks((prevValue) => [
        ...prevValue,
        { hardwareId: key, name: value.name, gateways: value.gateways },
      ]);
    });
    setLoading(false);
    console.log('availableNetworks', availableNetworks);
  }, [hardwareContext.hardwareData]);

  if (loading) {
    return <IonLoading isOpen={loading} message={'Reading data...'} />;
  } else {
    return (
      <IonPage id='main'>
        <ORYXHeader backTarget='/measurements' />
        <IonContent className='ion-padding' id='main' color='light'>
          <IonGrid fixed className=''>
            <IonRow className=''>
              <IonCol size='12' class='ion-justify-content-center' className='ORYX_title'>
                <IonTitle>Please select the network your are using</IonTitle>
              </IonCol>
            </IonRow>

            <IonRow className='ion-align-items-center ion-justify-content-center ion-align-self-center'>
              {availableNetworks.length < 1 ? (
                <IonSpinner />
              ) : (
                availableNetworks.map((hardware) => {
                  return (
                    <IonCol size='6' className='' key={hardware.hardwareId}>
                      <RouterCard hardwareInfo={hardware} />
                    </IonCol>
                  );
                })
              )}
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
