import React from 'react';
import { useHardware } from '../hooks/useHardware/useHardware';
import { HardwareCollection } from '../model';
import { useAuthContext } from './authContext';

type HardwareContextProps =
  | {
      hardwareData: HardwareCollection;
      selectedHardware: string;
      buildDocTree: () => Promise<void>;
    }
  | undefined;

const HardwareContext = React.createContext<HardwareContextProps>(undefined);

interface HardwareProviderProps {
  children: React.ReactNode;
}
export const HardwareProvider = ({ children }: HardwareProviderProps) => {
  const userId = useAuthContext().state.userState?.user.uid;

  let value: HardwareContextProps = undefined;
  if (userId) {
    const hardwareHook = useHardware(userId);

    const buildDocTree = async () => {
      await hardwareHook.buildDocTree();
    };

    value = {
      hardwareData: hardwareHook.hardwareData,
      selectedHardware: hardwareHook.selectedHardware,
      buildDocTree: buildDocTree,
    };
  }

  return <HardwareContext.Provider value={value}>{children}</HardwareContext.Provider>;
};

export const useHardwareContext = () => {
  const context = React.useContext(HardwareContext);
  if (context === undefined) {
    throw new Error(
      'useHardwareContext() is not being used within the proper scope. It can only be used within GateWayCard component',
    );
  }
  return context;
};
