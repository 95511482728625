/** The Run Kinematics report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonCard,
  IonLoading,
  IonToggle,
  IonCardContent,
  IonCardHeader,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { Report, RunReportData, StridesData } from '../../../model';
import { toGraphData, flattenData, toVariabilityData, getLeftRightSeriesData } from '../components/DataFunctions';
import { colorLeft, colorRight } from '../components/CustomGraphComponents';

import '../components/Graphs/graphCard.css';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { KinematicsGraph, VariabilityOverviewGraph } from '../components/Graphs';
import { useEffect, useMemo, useState } from 'react';

type KinematicsReportProps = RouteComponentProps & {
  report: RunReportData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunKinematicsReportTab = ({ report }: KinematicsReportProps) => {
  const data = report as RunReportData | undefined;
  const [loading, setLoading] = useState(false);
  // console.log(seriesData.seriesPelvis_Sagittal);

  const annotations_Left = data?.reportId.pointsOfInterests.col0;
  const annotations_Right = data?.reportId.pointsOfInterests.col1;

  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);
  const [seriesPelvis_Sagittal, setSeriesPelvis_Sagittal] = useState<ApexOptions['series']>([]);
  const [seriesPelvis_Coronal, setSeriesPelvis_Coronal] = useState<ApexOptions['series']>([]);
  const [seriesPelvis_Transversal, setSeriesPelvis_Transversal] = useState<ApexOptions['series']>([]);
  const [seriesHip_Sagittal, setSeriesHip_Sagittal] = useState<ApexOptions['series']>([]);
  const [seriesHip_Coronal, setSeriesHip_Coronal] = useState<ApexOptions['series']>([]);
  const [seriesHip_Transversal, setSeriesHip_Transversal] = useState<ApexOptions['series']>([]);
  const [seriesKnee_Sagittal, setSeriesKnee_Sagittal] = useState<ApexOptions['series']>([]);
  const [seriesKnee_Coronal, setSeriesKnee_Coronal] = useState<ApexOptions['series']>([]);
  const [seriesKnee_Transversal, setSeriesKnee_Transversal] = useState<ApexOptions['series']>([]);
  const [seriesAnkle_Sagittal, setSeriesAnkle_Sagittal] = useState<ApexOptions['series']>([]);
  const [seriesAnkle_Coronal, setSeriesAnkle_Coronal] = useState<ApexOptions['series']>([]);
  const [seriesAnkle_Transversal, setSeriesAnkle_Transversal] = useState<ApexOptions['series']>([]);

  const toggleShowAllStrides = () => {
    setShowAllStrides(!showAllStrides);
    localStorage.setItem('showAllStridesKinematics', !showAllStrides ? 'true' : 'false');
  };

  useEffect(() => {
    const allStridesLocalStorage_Item = localStorage.getItem('showAllStridesKinematics');
    console.log('Use effect triggered: ', allStridesLocalStorage_Item);
    if (allStridesLocalStorage_Item === 'true') {
      setShowAllStrides(true);
      setSeriesPelvis_Sagittal(getLeftRightSeriesData(data?.stridesLeft.leftPelvis, data?.stridesRight.rightPelvis));
      setSeriesPelvis_Coronal(
        getLeftRightSeriesData(
          data?.stridesCoronalLeft.leftPelvis_Coronal,
          data?.stridesCoronalRight.rightPelvis_Coronal,
        ),
      );
      setSeriesPelvis_Transversal(
        getLeftRightSeriesData(
          data?.stridesTransversalLeft.leftPelvis_Transversal,
          data?.stridesTransversalRight.rightPelvis_Transversal,
        ),
      );

      setSeriesHip_Sagittal(getLeftRightSeriesData(data?.stridesLeft.leftHip, data?.stridesRight.rightHip));
      setSeriesHip_Coronal(
        getLeftRightSeriesData(data?.stridesCoronalLeft.leftHip_Coronal, data?.stridesCoronalRight.rightHip_Coronal),
      );
      setSeriesHip_Transversal(
        getLeftRightSeriesData(
          data?.stridesTransversalLeft.leftHip_Transversal,
          data?.stridesTransversalRight.rightHip_Transversal,
        ),
      );

      setSeriesKnee_Sagittal(getLeftRightSeriesData(data?.stridesLeft.leftKnee, data?.stridesRight.rightKnee));
      setSeriesKnee_Coronal(
        getLeftRightSeriesData(data?.stridesCoronalLeft.leftKnee_Coronal, data?.stridesCoronalRight.rightKnee_Coronal),
      );
      setSeriesKnee_Transversal(
        getLeftRightSeriesData(
          data?.stridesTransversalLeft.leftKnee_Transversal,
          data?.stridesTransversalRight.rightKnee_Transversal,
        ),
      );

      setSeriesAnkle_Sagittal(getLeftRightSeriesData(data?.stridesLeft.leftAnkle, data?.stridesRight.rightAnkle));
      setSeriesAnkle_Coronal(
        getLeftRightSeriesData(
          data?.stridesCoronalLeft.leftAnkle_Coronal,
          data?.stridesCoronalRight.rightAnkle_Coronal,
        ),
      );
      setSeriesAnkle_Transversal(
        getLeftRightSeriesData(
          data?.stridesTransversalLeft.leftAnkle_Transversal,
          data?.stridesTransversalRight.rightAnkle_Transversal,
        ),
      );
    } else {
      localStorage.setItem('showAllStridesKinematics', 'false');
      setShowAllStrides(false);
      setSeriesPelvis_Sagittal([
        { type: 'line', name: 'Left', data: toVariabilityData(data?.reportId.kinematicData.col0), color: colorLeft },
        { type: 'line', name: 'Right', data: toVariabilityData(data?.reportId.kinematicData.col7), color: colorRight },
      ]);
      setSeriesPelvis_Coronal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col0),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col1),
          color: colorRight,
        },
      ]);
      setSeriesPelvis_Transversal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col0),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col1),
          color: colorRight,
        },
      ]);

      setSeriesHip_Sagittal([
        { type: 'line', name: 'Left', data: toVariabilityData(data?.reportId.kinematicData.col1), color: colorLeft },
        { type: 'line', name: 'Right', data: toVariabilityData(data?.reportId.kinematicData.col2), color: colorRight },
      ]);
      setSeriesHip_Coronal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col2),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col3),
          color: colorRight,
        },
      ]);
      setSeriesHip_Transversal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col2),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col3),
          color: colorRight,
        },
      ]);

      setSeriesKnee_Sagittal([
        { type: 'line', name: 'Left', data: toVariabilityData(data?.reportId.kinematicData.col3), color: colorLeft },
        { type: 'line', name: 'Right', data: toVariabilityData(data?.reportId.kinematicData.col4), color: colorRight },
      ]);
      setSeriesKnee_Coronal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col4),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col5),
          color: colorRight,
        },
      ]);
      setSeriesKnee_Transversal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col4),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col5),
          color: colorRight,
        },
      ]);

      setSeriesAnkle_Sagittal([
        { type: 'line', name: 'Left', data: toVariabilityData(data?.reportId.kinematicData.col5), color: colorLeft },
        { type: 'line', name: 'Right', data: toVariabilityData(data?.reportId.kinematicData.col6), color: colorRight },
      ]);
      setSeriesAnkle_Coronal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col6),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Coronal.col7),
          color: colorRight,
        },
      ]);
      setSeriesAnkle_Transversal([
        {
          type: 'line',
          name: 'Left',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col6),
          color: colorLeft,
        },
        {
          type: 'line',
          name: 'Right',
          data: toVariabilityData(data?.reportId.kinematicData_Transversal.col7),
          color: colorRight,
        },
      ]);
    }
  }, [data, showAllStrides]);

  if (!data) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonLoading cssClass='my-custom-class' isOpen={loading} message={'Drawing graphs, one moment please...'} />

          <IonGrid fixed>
            {/* Pelvis */}
            <IonRow className=''>
              <IonCol size='12' className=''>
                <IonCard className=''>
                  <IonCardHeader className='attractorCard_Header'>
                    <IonGrid className=''>
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='9' className=''>
                          <IonText className='ORYX_GraphTitle'>Pelvis</IonText>
                        </IonCol>

                        <IonCol size='3' className='ion-justify-content-center'>
                          <IonRow className='ion-no-padding ion-justify-content-center'>
                            <IonText className='attractorCard_Title'>Show all Strides</IonText>
                          </IonRow>
                          <IonRow className='ion-no-padding ion-justify-content-center'>
                            <IonToggle
                              mode='ios'
                              className='icon-custom'
                              color='primary'
                              checked={showAllStrides}
                              onIonChange={(e) => {
                                toggleShowAllStrides();
                              }}></IonToggle>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesPelvis_Sagittal}
                          title={'Sagittal'}
                          subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesPelvis_Coronal}
                          title={'Frontal'}
                          subtitle={'Drop (-) / Hike (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesPelvis_Transversal}
                          title={'Transversal'}
                          subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Hip */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className=''>
                  <IonCardHeader className='attractorCard_Header'>
                    <IonGrid className=''>
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='9' className=''>
                          <IonText className='ORYX_GraphTitle'>Hip</IonText>
                        </IonCol>

                        <IonCol size='3' className='ion-justify-content-center'></IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesHip_Sagittal}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesHip_Coronal}
                          title={'Frontal'}
                          subtitle={'Abduction (-) / Adduction (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesHip_Transversal}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Knee */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className=''>
                  <IonCardHeader className='attractorCard_Header'>
                    <IonGrid className=''>
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='9' className=''>
                          <IonText className='ORYX_GraphTitle'>Knee</IonText>
                        </IonCol>

                        <IonCol size='3' className='ion-justify-content-center'></IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesKnee_Sagittal}
                          title={'Sagittal'}
                          subtitle={'Extension (-) / Flexion (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesKnee_Coronal}
                          title={'Frontal'}
                          subtitle={'Valgus (-) / Varus (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesKnee_Transversal}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Ankle */}
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className=''>
                  <IonCardHeader className='attractorCard_Header'>
                    <IonGrid className=''>
                      <IonRow className='ion-align-items-center'>
                        <IonCol size='9' className=''>
                          <IonText className='ORYX_GraphTitle'>Ankle</IonText>
                        </IonCol>

                        <IonCol size='3' className='ion-justify-content-center'></IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesAnkle_Sagittal}
                          title={'Sagittal'}
                          subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesAnkle_Coronal}
                          title={'Frontal'}
                          subtitle={'Eversion (-) / Inversion (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraph
                          data={seriesAnkle_Transversal}
                          title={'Transversal'}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          annotations_Left={annotations_Left}
                          annotations_Right={annotations_Right}
                          strokeWidth={showAllStrides ? 1 : 2}
                        />
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
