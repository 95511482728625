/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * ORYX Test page.
 */

import { IonPage, IonContent, IonGrid, IonTitle, IonCol, IonRow, IonSpinner, IonIcon, IonText } from '@ionic/react';
import { ORYXHeader } from '../../../components/menuItems/Header';
import { GatewayCard } from './GatewayCard';
import './ORYX.css';
import { useEffect, useState } from 'react';
import { GatewayInfo, GatewaySocket, SOCKET_OPTIONS } from './context/socket';
import { useHardwareContext } from '../../../components/hardwareContext';
import { io } from 'socket.io-client';
import { RouteComponentProps, useParams } from 'react-router';

interface ORYXTestParams {
  hardwareId: string;
}

export const ORYXTest = () => {
  const [availableGateways, setAvailableGateways] = useState<GatewayInfo[]>([]);
  const hardwareContext = useHardwareContext();
  const params = useParams<ORYXTestParams>();
  const [loading, setLoading] = useState(true);
  console.log('params: ', params);
  console.log('hardwareContext: ', hardwareContext.hardwareData[params.hardwareId]);
  const network = hardwareContext.hardwareData[params.hardwareId];
  const backTarget = history.state.state?.from;
  const [connectedGateway, setConnectedGateway] = useState('');

  useEffect(() => {
    if (!network) {
      return;
    }

    Object.entries(network.gateways).map(([key, value]) => {
      const newSocket: GatewaySocket = io(`http://${value.socketURL}`, SOCKET_OPTIONS);
      // const newSocket: GatewaySocket = io(`http://${value.hostName}.local:8080`, SOCKET_OPTIONS);

      setAvailableGateways((prevValue) => [
        ...prevValue,
        {
          gatewaySocket: newSocket,
          gatewayInfo: value,
          gatewayUUID: key,
        },
      ]);
    });

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [network]);

  useEffect(() => {
    console.log('connectedGateway: ', connectedGateway);
  }, [connectedGateway]);

  return (
    <IonPage id='main'>
      <ORYXHeader backTarget={backTarget} />
      <IonContent className='ion-padding' id='main' color='light'>
        <IonGrid fixed>
          {loading ? (
            <IonSpinner />
          ) : (
            <>
              {availableGateways.length < 1
                ? 'No gateways found.'
                : availableGateways.map((thisSocket) => {
                    console.log('thisSocket: ', availableGateways);
                    const className = () => {
                      if (connectedGateway !== '') {
                        return connectedGateway === thisSocket.gatewayInfo.hostName ? '' : 'ion-hide';
                      } else {
                        return '';
                      }
                    };

                    return (
                      <IonRow key={thisSocket.gatewayUUID} className={className()}>
                        <GatewayCard
                          socket={thisSocket.gatewaySocket}
                          gatewayUUID={thisSocket.gatewayUUID}
                          gatewayInfo={thisSocket.gatewayInfo}
                          connectedGateway={setConnectedGateway}
                        />
                      </IonRow>
                    );
                  })}
            </>
          )}
        </IonGrid>
      </IonContent>
      {/* <ErrorToast message={isError ? isError.message : ''} clearError={() => clearError()} /> */}
    </IonPage>
  );
};
