/**
 * ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Manages and controls caching inside ORYX app. This prevents alot of unessecary interactions with firebase that don't need to happen.
 * Primary function is to parse and stringify firebase document models (most of these types are defined in /src/model) to JSON and back.
 * It also supports type parameters to make it easier to load/set cache data. Useful for when getting static data from a user that
 * doesn't change or need to change very often, and would be better off being cached to save resources and bandwith.
 */

import { Preferences } from '@capacitor/preferences';

/**
 * Sets and stores data by parsing objects into JSON, and storing it in cache.
 *
 * @type T The type of the data to cache.
 * @param cacheData JSON data to stringify and cache into localPreferences
 * @param cacheKey The cache key (identifier) to cache data into. This key can be used by getCache to retrieve the data once again.
 */
export const setCache = async <T>(cacheData: T, cacheKey: string) => {
  await Preferences.set({ key: cacheKey, value: JSON.stringify(cacheData) });
  console.info(`CACHE CONTROLLER > Cache for ${cacheKey} set.`);
};

/**
 * Gets cache (if present) and parses to object of type `T`.
 *
 * @type T The type of the JSON object being parsed and returned.
 * @param cacheKey The cache-key to read from.
 *
 * @returns `T` if cache is present or `null` if otherwise.
 */
export const getCache = async <T>(cacheKey: string): Promise<T | null> => {
  const localPreferencesCache = (await Preferences.get({ key: cacheKey })).value;
  if (localPreferencesCache != null) {
    try {
      const cache: T = JSON.parse(localPreferencesCache);
      console.log(`CACHE CONTROLLER > Cache for ${cacheKey} retrieved.`);
      return cache;
    } catch (e) {
      console.info(`CACHE CONTROLLER > Error parsing value of cached key ${cacheKey}`);
      return null;
    }
  }
  console.info(`CACHE CONTROLLER > Cache for ${cacheKey} does not exist.`);
  return null;
};

/**
 *
 * @type T The type of the JSON object being parsed and returned. It must be an Array
 * @param cacheKey The cache-key to read from.
 * @returns
 */
export const updateArrayCache = async <T>(cacheData: T, cacheKey: string) => {
  const localPreferencesCache = (await Preferences.get({ key: cacheKey })).value;

  if (localPreferencesCache != null) {
    try {
      const cache: T[] = JSON.parse(localPreferencesCache);

      cache.push(cacheData);

      await setCache(cache, cacheKey);
      console.log(`CACHE CONTROLLER > Cache for ${cacheKey} updated.`);

      return;
    } catch (e) {
      console.info(`CACHE CONTROLLER > Error parsing value of cached key ${cacheKey}.`);
      return;
    }
  }
  console.info(
    `CACHE CONTROLLER > Cache for ${cacheKey} does not exist, creating empty array cache and filling with data.`,
  );
  const newCache: T[] = [cacheData];
  await setCache(newCache, cacheKey);
  return;
};
