import React from 'react';
import { useReportInfo, useReports } from '../hooks/useReports';
import { ReportDict, ReportInfoDict } from '../model';
import { useAuthContext } from './authContext';

type ReportContextProps =
  | {
      reportInfoDict: ReportInfoDict;
      reportDict: ReportDict;
      isLoading: 'reportInfo' | 'reports' | undefined;
      isError: Error | undefined;
      clearError: () => void;
      doDelete: (reportId: string) => void;
      getReportData: (reportId: string) => any;
      dataReady: boolean;
      data: any;
    }
  | undefined;
const reportContext = React.createContext<ReportContextProps>(undefined);

interface ReportProviderProps {
  children: React.ReactNode;
}
export const ReportProvider = ({ children }: ReportProviderProps) => {
  const userId = useAuthContext().state.userState?.user.uid;

  let value: ReportContextProps = undefined;
  if (userId) {
    const reportInfoHook = useReportInfo(userId);
    const reportHook = useReports(userId, reportInfoHook.reportInfoDict);

    const isLoading = reportInfoHook.isLoading ? 'reportInfo' : reportHook.isLoading ? 'reports' : undefined;
    const isError = reportInfoHook.isError ? reportInfoHook.isError : reportHook.isError;
    const clearError = () => {
      reportInfoHook.clearError();
      reportHook.clearError();
    };
    const doDelete = (reportId: string) => {
      reportHook.doDelete(reportId);
    };
    const getReportData = (reportId: string) => {
      reportHook.getReportData(reportId);
    };

    const dataReady = reportHook.dataReady;

    value = {
      reportInfoDict: reportInfoHook.reportInfoDict,
      reportDict: reportHook.reportDict,
      isLoading: isLoading,
      isError: isError,
      clearError: clearError,
      doDelete: doDelete,
      getReportData: getReportData,
      dataReady: dataReady,
      data: reportHook.data,
    };
  }
  return <reportContext.Provider value={value}>{children}</reportContext.Provider>;
};

export const useReportContext = () => {
  const context = React.useContext(reportContext);
  if (context === undefined) {
    throw new Error('useReportContext must be used within a ReportProvider');
  }
  return context;
};
