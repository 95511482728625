import { Preferences } from '@capacitor/preferences';
import 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import {
  BillingCollection,
  BillInstance as BillingInstance,
  LicenseInstance,
  LicensesCollection,
  OrganizationInfo,
  UserId,
  UserInfo,
  UserInstance,
  UsersCollection,
} from '../../model';
import { getCache, setCache } from '../../utilities/CacheController';
import { myFirebase } from '../firebaseConfig';

const db = myFirebase.firestore();

type UseUserInfoHook = {
  userInfo: UserInfo;
  buildDocTree: () => Promise<void>;
};

const DEFAULT_USERINFO_STATE: UserInfo = {
  organization_id: '',
  firstName: '',
  lastName: '',
  role: '',
  organization: {
    org_name: '',
    billing: {},
    licenses: {},
  },
};

const USERINFO_CACHE_KEY = 'userinfo_cache';

export const useUserInfo = (userId: UserId): UseUserInfoHook => {
  const [dataReady, setDataReady] = useState<boolean>(false);
  const [data, setData] = useState<UserInfo>(DEFAULT_USERINFO_STATE);

  const buildDocTree = async () => {
    //Attempt to load cache first, and proceed to pulling from firebase if not present
    const cachedValue = await getCache<UserInfo>(USERINFO_CACHE_KEY);

    if (cachedValue != null) {
      setData(cachedValue);
      setDataReady(true);
      return;
    }

    const userDocRef = db.collection('users').doc(userId);
    const userDocs = await userDocRef.get();
    const userInfoData = userDocs.data() as UserInfo;

    const organizationDocRef = db.collection('organizations').doc(userInfoData.organization_id);
    const organizationDocs = await organizationDocRef.get();
    const organizationInfoData = organizationDocs.data() as OrganizationInfo;

    userInfoData.organization = organizationInfoData;

    // Handle collecting license data of user
    const licenseCollectionRef = organizationDocRef.collection('licenses');
    const licenseDocs = await licenseCollectionRef.get();

    const licensesCollectionData: LicensesCollection = {};

    for (const li of licenseDocs.docs) {
      const licenseInstance = li.data() as LicenseInstance;

      licensesCollectionData[li.id] = {
        ...licenseInstance,
      };
    }

    userInfoData.organization.licenses = licensesCollectionData;

    // Handle collecting Billing data of user
    const billingCollectionRef = organizationDocRef.collection('billing');
    const billingDocs = await billingCollectionRef.get();

    const billingCollectionData: BillingCollection = {};

    for (const bi of billingDocs.docs) {
      const billingInstance = bi.data() as BillingInstance;

      billingCollectionData[bi.id] = {
        ...billingInstance,
      };
    }

    userInfoData.organization.billing = billingCollectionData;

    // Get admin related info when permissable.
    if (userInfoData.role == 'admin') {
      const usersCollectionRef = db.collection('users').where('organization_id', '==', userInfoData.organization_id);
      const userDocs = await usersCollectionRef.get();

      const usersCollectionData: UsersCollection = {};

      for (const ui of userDocs.docs) {
        const userInstance = ui.data() as UserInstance;

        usersCollectionData[ui.id] = {
          ...userInstance,
        };
      }

      userInfoData.organization.users = usersCollectionData;
    }

    setData(userInfoData);
    setDataReady(true);
    await setCache<UserInfo>(userInfoData, USERINFO_CACHE_KEY);
  };

  // onMount, build the doctree (this happens once per 'reload or refresh')
  useEffect(() => {
    buildDocTree();
  }, []);

  const hook: UseUserInfoHook = {
    userInfo: data,
    buildDocTree: buildDocTree,
  };

  return useMemo(() => hook, [dataReady]);
};
