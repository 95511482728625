/** Report detail page, holding tabs for analysis, kinematics, and overview */
import {
  IonContent,
  IonIcon,
  IonLabel,
  IonLoading,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonToast,
} from '@ionic/react';
import { airplaneOutline, analyticsOutline, bodyOutline, informationCircleOutline } from 'ionicons/icons';
import * as React from 'react';
import { useParams } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { OverviewReportTab } from '../../OverviewReportTab';
import {
  RunAttractorsReportTab,
  RunningStatusReportTab,
  RunScissorsReportTab,
  RunVariabilityReportTab,
  RunKinematicsReportTab,
} from '../../RunReportV2';
import { useEffect, useState } from 'react';

// Custom Icons
import ORYX_VariabilityIcon from '../../../../assets/icons/ORYX_VariabilityIcon.svg';
import ORYX_ScissorsIcon from '../../../../assets/icons/ORYX_ScissorsIcon.svg';
import ORYX_AttractorsIcon from '../../../../assets/icons/ORYX_AttractorsIcon.svg';

import ORYX_ReportStatusIcon from '../../../../assets/icons/ORYX_ReportStatusIcon.svg';
import ORYX_KinematicsIcon from '../../../../assets/icons/ORYX_KinematicsIcon.svg';
import ORYX_InformationIcon from '../../../../assets/icons/ORYX_InformationIcon.svg';

//   Custom css
import './index.css';
import { RunAnalysisReportTab } from '../../RunReportV2/RunAnalysisReportTab';

interface ReportTab {
  url: string;
  title: string;
  status: string;
  icon: string;
}

const runTabs: ReportTab[] = [
  {
    title: 'Analysis',
    url: `analysis`,
    status: 'active',
    icon: ORYX_ReportStatusIcon,
  },
  {
    title: 'Running Status',
    url: `runningStatus`,
    status: 'active',
    icon: ORYX_AttractorsIcon,
  },
  {
    title: 'Scissors',
    url: 'scissors',
    status: 'active',
    icon: ORYX_ScissorsIcon,
  },
  {
    title: 'Kinematics',
    url: 'kinematics',
    status: 'active',
    icon: ORYX_KinematicsIcon,
  },
  {
    title: 'Variability',
    url: 'variability',
    status: 'active',
    icon: ORYX_VariabilityIcon,
  },

  // {
  //   title: 'Overview',
  //   url: 'overview',
  //   status: 'active',
  //   icon: ORYX_InformationIcon,
  // },
];

interface RunTabsProps {
  id: string;
  data: any;
}

export const RunTabBar = ({ id, data }: RunTabsProps) => {
  const [activeRunTab, setActiveRunTab] = useState('Analysis');
  // console.log('data', data);

  // Set custom actions on the tabs
  const handleTabWillChange = (e: any) => {
    setActiveRunTab(e.detail.tab);
  };

  return (
    <IonTabs onIonTabsWillChange={handleTabWillChange}>
      <IonRouterOutlet id='main'>
        <Route
          path='/reportsV2/:id/runningStatus'
          render={(props) => {
            return <RunningStatusReportTab {...props} report={data} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/:id/analysis'
          render={(props) => {
            return <RunAttractorsReportTab {...props} report={data} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/:id/scissors'
          render={(props) => {
            return <RunScissorsReportTab {...props} report={data} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/:id/variability'
          render={(props) => {
            return <RunVariabilityReportTab {...props} report={data} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/:id/kinematics'
          render={(props) => {
            return <RunKinematicsReportTab {...props} report={data} />;
          }}
          exact={true}
        />

        <Route path={`/reportsV2/${id}`} render={() => <Redirect to={`/reportsV2/${id}/analysis`} />} exact={true} />
      </IonRouterOutlet>

      <IonTabBar slot='bottom' mode='ios'>
        {runTabs.map((tab, _) => {
          let tabClassNames = '';
          if (tab.status === 'disabled') {
            tabClassNames = 'disabled';
          } else if (location.pathname === tab.url) {
            tabClassNames = 'ion-tab-button_selected';
          }
          return (
            <IonTabButton
              mode='ios'
              tab={tab.title}
              href={`/reportsV2/${id}/${tab.url}`}
              className={activeRunTab === tab.title ? 'ion-tab-button_selected' : ''}
              key={tab.title}>
              <IonIcon icon={tab.icon} />
              <IonLabel>{tab.title}</IonLabel>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </IonTabs>
  );
};
